import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './../../Global.css';
import Button from '../../components/Button';
import { formatDateMMDDYYYYHHMMSS } from '../../utils';

const ITEMS_PER_PAGE = 10;

const ERROR_MESSAGE = 'បណ្តាញមានបញ្ហា។ សូមព្យាយាមម្តងទៀតនៅពេលក្រោយ។';
const DELETE_CONFIRM = 'តើអ្នកប្រាកដថាចង់លុបកាលវិភាគនេះមែនទេ?';
const DELETE_SUCCESS = 'កាលវិភាគត្រូវបានលុបដោយជោគជ័យ។';

const ROUTES = {
  edit: '/edit-schedule',
  new: '/new-schedule',
  view: '/view-schedule'
};

const STATUS = {
  COMPLETED: 'បានបញ្ចប់',
  PENDING: 'មិនទាន់បញ្ចប់'
};

function Schedule() {
  const urlAPI = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [userRole, setUserRole] = useState(null);

  const getParticipantsForSchedule = useCallback(async (schedule, token) => {
    const response = await axios.get(
      `${urlAPI}/meeting-participant/get-multi/${schedule.id}`,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return {
      ...schedule,
      participant_names: response.data.data.participant_name
    };
  }, [urlAPI]);

  const fetchSchedule = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const permissionId = localStorage.getItem('permission-role');
      const userId = localStorage.getItem('user-id');

      setUserRole(Number(permissionId));

      const response = await axios.get(
        `${urlAPI}/schedule/get/${permissionId}/${userId}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      const scheduleData = response.data.data || [];
      const enrichedScheduleData = await Promise.all(
        scheduleData.map(schedule => getParticipantsForSchedule(schedule, token))
      );

      setData(enrichedScheduleData);
      setError(null);
    } catch (err) {
      const errorMessage = err.response?.status === 400
        ? err.response.data.message
        : ERROR_MESSAGE;
      console.error(errorMessage);
    } finally {
      setLoading(false);
    }
  }, [urlAPI, getParticipantsForSchedule]);

  const deleteSchedule = async (scheduleId) => {
    if (!window.confirm(DELETE_CONFIRM)) return;

    try {
      const token = localStorage.getItem('token');
      await axios.delete(
        `${urlAPI}/schedule/delete/${scheduleId}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      alert(DELETE_SUCCESS);
      fetchSchedule();
    } catch (err) {
      const errorMessage = err.response?.status === 400
        ? err.response.data.message
        : ERROR_MESSAGE;
      setError(errorMessage);
    }
  };

  const getActiveStatus = (end_date) => {
    const endDate = new Date(end_date);
    const currentDate = new Date();
    return endDate.getTime() < currentDate.getTime() ? STATUS.COMPLETED : STATUS.PENDING;
  };

  const handleNavigation = (data, action) => {
    const route = ROUTES[action];
    if (route) {
      navigate(route, data ? { state: { data } } : undefined);
    }
  };

  useEffect(() => {
    fetchSchedule();
  }, [fetchSchedule]);

  const getPaginatedData = () => {
    const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
    const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
    return {
      currentItems: data.slice(indexOfFirstItem, indexOfLastItem),
      totalPages: Math.ceil(data.length / ITEMS_PER_PAGE),
      indexOfFirstItem
    };
  };

  const renderTableRow = (schedule, index, indexOfFirstItem) => (
    <tr key={schedule.id}>
      <td>{indexOfFirstItem + index + 1}</td>
      <td>{formatDateMMDDYYYYHHMMSS(schedule.start_date) || 'គ្មាន'}</td>
      <td>{schedule.title || 'គ្មាន'}</td>
      <td>{schedule.participant_names || 'គ្មាន'}</td>
      <td>{schedule.place || 'គ្មាន'}</td>
      <td>{getActiveStatus(schedule.end_date)}</td>
      {userRole === 3 ? (
        <td><Button onClick={() => handleNavigation(schedule, 'view')} className="view-button" label="មើល" /></td>
      ) : (
        <>
          <td><Button onClick={() => handleNavigation(schedule, 'edit')} className="update-button" label="កែប្រែ" /></td>
          {userRole === 1 && (
            <td><Button onClick={() => deleteSchedule(schedule.id)} className="delete-button" label="លុប" /></td>
          )}
        </>
      )}
    </tr>
  );

  if (loading) {
    return (
      <div className="loading-container">
        <div className="spinner" />
        <p className="loading-text">កំពុងផ្ទុក...</p>
      </div>
    );
  }

  const { currentItems, totalPages, indexOfFirstItem } = getPaginatedData();

  return (
    <div className="home-container">
      <h1>តារាងកាលវិភាគ</h1>
      {userRole !== 3 && (
        <Button
          onClick={() => handleNavigation(null, 'new')}
          className="update-button"
          label="បង្កើតថ្មី"
        />
      )}

      {error && (
        <div className="error-message-container">
          <p className="error-message">{error}</p>
        </div>
      )}

      {data.length > 0 ? (
        <>
          <table className="data-table">
            <thead>
              <tr>
                <th>ល.រ</th>
                <th>កាលបរិច្ឆេទ</th>
                <th>កម្មវត្ថុ</th>
                <th>អ្នកចូលរួម</th>
                <th>ទីកន្លែង</th>
                <th>ស្ថានភាព</th>
                <th colSpan={userRole === 1 ? 2 : 1}>សកម្មភាព</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((schedule, index) =>
                renderTableRow(schedule, index, indexOfFirstItem)
              )}
            </tbody>
          </table>
          <div className="pagination">
            {Array.from({ length: totalPages }, (_, i) => (
              <button
                key={i + 1}
                onClick={() => setCurrentPage(i + 1)}
                className={`pagination-button ${currentPage === i + 1 ? 'active' : ''}`}
              >
                {i + 1}
              </button>
            ))}
          </div>
        </>
      ) : (
        <p>មិនមានកាលវិភាគ</p>
      )}
    </div>
  );
}

export default Schedule;
