import React, { useState } from 'react';
import axios from 'axios';
import './Login.css';

function Login() {
  const urlAPI = process.env.REACT_APP_API_URL;
  const [formData, setFormData] = useState({
    username: '',
    password: ''
  });
  const [error, setError] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleError = (err) => {
    const errorMessage = err.response?.status === 400
      ? err.response.data.message
      : 'បណ្តាញមានបញ្ហា។ សូមព្យាយាមម្តងទៀតនៅពេលក្រោយ។';
    setError(errorMessage);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${urlAPI}/auth/login`, formData);
      const { token } = response.data;

      if (token) {
        localStorage.setItem('token', token);
        window.location.href = '/schedule';
      } else {
        setError('ការចូលបរាជ័យ សូមពិនិត្យព័ត៌មានរបស់អ្នកឡើងវិញ។');
      }
    } catch (err) {
      handleError(err);
    }
  };

  const renderError = () => error && (
    <div className="error-message-container">
      <p className="error-message">{error}</p>
    </div>
  );

  return (
    <div className="login-container">
      <div className="login-card">
        <h2>ចូលប្រើប្រាស់</h2>
        {renderError()}
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="username"
            placeholder="ឈ្មោះអ្នកប្រើប្រាស់"
            value={formData.username}
            onChange={handleInputChange}
            className="login-input"
          />
          <input
            type="password"
            name="password"
            placeholder="ពាក្យសម្ងាត់"
            value={formData.password}
            onChange={handleInputChange}
            className="login-input"
          />
          <button type="submit" className="login-button">
            ចូល
          </button>
        </form>
      </div>
    </div>
  );
}

export default Login;
