import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '../../../Global-Form.css';
import axios from 'axios';
import Button from '../../../components/Button';

function EditDepartment() {
    const urlAPI = process.env.REACT_APP_API_URL;
    const location = useLocation();
    const navigate = useNavigate();
    const { data } = location.state || {};

    const [id] = useState(data?.id || '');
    const [departmentName, setDepartmentName] = useState(data?.department_name || '');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false);

    const handleUpdateDepartment = async () => {
        const token = localStorage.getItem('token');
        await axios.post(
            `${urlAPI}/department/update/${data.id}`,
            { departmentName },
            { headers: { Authorization: `Bearer ${token}` } }
        );
    };

    const handleSuccess = () => {
        setSuccess('អង្គភាពត្រូវបានធ្វើបច្ចុប្បន្នភាពដោយជោគជ័យ!');
        setError('');
        setTimeout(() => navigate('/department'), 1000);
    };

    const handleError = (err) => {
        const errorMessage = err.response?.status === 400
            ? err.response.data.message
            : 'បណ្តាញមានបញ្ហា។ សូមព្យាយាមម្តងទៀតនៅពេលក្រោយ។';
        
        setError(errorMessage);
        setSuccess('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!data?.id) return;

        try {
            setLoading(true);
            await handleUpdateDepartment();
            handleSuccess();
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    const renderInputField = (value, onChange, disabled = false, placeholder = '') => (
        <input
            type="text"
            value={value}
            onChange={onChange}
            disabled={disabled}
            placeholder={placeholder}
            required={!disabled}
            className="input-field"
        />
    );

    const renderButtons = () => (
        <td colSpan={2} style={{ textAlign: 'right' }}>
            <Button
                onClick={() => navigate('/department')}
                variant="close-button"
                label="ចាកចេញ"
            />
            <Button
                type="submit"
                variant="save-button"
                label={loading ? 'កំពុងរក្សាទុក...' : 'រក្សាទុក'}
                disabled={loading}
            />
        </td>
    );

    const renderForm = () => (
        <form onSubmit={handleSubmit} className="form-container">
            <table className="table-container">
                <tbody>
                    <tr>
                        <td><label>លេខកូដ:</label></td>
                        <td><label>ឈ្មោះ: </label></td>
                    </tr>
                    <tr>
                        <td>
                            {renderInputField(id, null, true)}
                        </td>
                        <td>
                            {renderInputField(
                                departmentName,
                                (e) => setDepartmentName(e.target.value),
                                false,
                                "បញ្ចូលឈ្មោះអង្គភាព"
                            )}
                        </td>
                    </tr>
                    <tr>
                        {renderButtons()}
                    </tr>
                </tbody>
            </table>
        </form>
    );

    const renderMessages = () => (
        <>
            {error && <p className="error-message">{error}</p>}
            {success && <p className="success-message">{success}</p>}
        </>
    );

    return (
        <div className="container">
            <h2>អង្គភាព</h2>
            {renderMessages()}
            {data ? renderForm() : <p>មិនមានទិន្នន័យអង្គភាព។</p>}
        </div>
    );
}

export default EditDepartment;
