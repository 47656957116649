import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '../../../Global-Form.css';
import axios from 'axios';
import Button from '../../../components/Button';

function EditRoom() {
    const urlAPI = process.env.REACT_APP_API_URL;
    const location = useLocation();
    const navigate = useNavigate();
    const { data } = location.state || {};

    const [formData, setFormData] = useState({
        id: data?.id || '',
        roomName: data?.name || ''
    });

    const [status, setStatus] = useState({
        error: '',
        success: '',
        loading: false
    });

    const handleInputChange = (field, value) => {
        setFormData(prev => ({
            ...prev,
            [field]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setStatus(prev => ({ ...prev, loading: true }));

        try {
            const token = localStorage.getItem('token');
            const response = await axios.post(
                `${urlAPI}/room/update/${data?.id}`,
                { roomName: formData.roomName },
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );

            if (response.status === 200) {
                setStatus({
                    success: 'បន្ទប់ត្រូវបានធ្វើបច្ចុប្បន្នភាពដោយជោគជ័យ!',
                    error: '',
                    loading: false
                });

                setTimeout(() => {
                    navigate('/room');
                }, 1000);
            }
        } catch (err) {
            const errorMessage = err.response?.status === 400
                ? err.response.data.message
                : 'បណ្តាញមានបញ្ហា។ សូមព្យាយាមម្តងទៀតនៅពេលក្រោយ។';

            setStatus({
                error: errorMessage,
                success: '',
                loading: false
            });
        }
    };

    const renderForm = () => (
        <form onSubmit={handleSubmit} className="form-container">
            <table className="table-container">
                <tbody>
                    <tr>
                        <td><label>លេខកូដ:</label></td>
                        <td><label>ឈ្មោះ:</label></td>
                    </tr>
                    <tr>
                        <td>
                            <input
                                type="text"
                                value={formData.id}
                                onChange={(e) => handleInputChange('id', e.target.value)}
                                disabled={true}
                                className="input-field"
                            />
                        </td>
                        <td>
                            <input
                                type="text"
                                value={formData.roomName}
                                onChange={(e) => handleInputChange('roomName', e.target.value)}
                                required
                                placeholder="បញ្ចូលឈ្មោះបន្ទប់"
                                className="input-field"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2} style={{ textAlign: 'right' }}>
                            <Button
                                onClick={() => navigate('/room')}
                                variant="close-button"
                                label="ចាកចេញ"
                            />
                            <Button
                                type="submit"
                                className="save-button"
                                label={status.loading ? 'កំពុងរក្សាទុក...' : 'រក្សាទុក'}
                                disabled={status.loading}
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </form>
    );

    return (
        <div className="container">
            <h2>បន្ទប់ប្រជុំ</h2>
            {data ? renderForm() : <p>មិនមានទិន្នន័យបន្ទប់។</p>}
            {status.error && (
                <div className="error-message-container">
                    <p className="error-message">{status.error}</p>
                </div>
            )}
            {status.success && <p className="success-message">{status.success}</p>}
        </div>
    );
}

export default EditRoom;
