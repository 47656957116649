import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './../../../Global-Form.css';
import axios from 'axios';
import Button from '../../../components/Button';
import Dropdown from '../../../components/Dropdown';

function EditPosition() {
    const urlAPI = process.env.REACT_APP_API_URL;
    const location = useLocation();
    const navigate = useNavigate();
    const { data } = location.state || {};

    const [formData, setFormData] = useState({
        id: data?.id || '',
        departmentId: data?.department_id || '',
        positionName: data?.position_name || ''
    });

    const [departments, setDepartments] = useState([]);
    const [status, setStatus] = useState({
        error: '',
        success: '',
        loading: false
    });

    const fetchDepartments = useCallback(async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${urlAPI}/department/gets`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setDepartments(response.data.data);
        } catch (err) {
            handleError(err);
        }
    }, [urlAPI]);

    useEffect(() => {
        fetchDepartments();
    }, [fetchDepartments]);

    const handleInputChange = (field, value) => {
        setFormData(prev => ({
            ...prev,
            [field]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setStatus(prev => ({ ...prev, loading: true }));

        try {
            const token = localStorage.getItem('token');
            await axios.post(
                `${urlAPI}/position/update/${data?.id}`,
                {
                    positionName: formData.positionName,
                    departmentId: formData.departmentId
                },
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );

            handleSuccess();
        } catch (err) {
            handleError(err);
        }
    };

    const handleSuccess = () => {
        setStatus({
            error: '',
            success: 'មុខតំណែងត្រូវបានធ្វើបច្ចុប្បន្នភាពដោយជោគជ័យ!',
            loading: false
        });

        setTimeout(() => {
            navigate('/position');
        }, 1000);
    };

    const handleError = (err) => {
        const errorMessage = err.response?.status === 400
            ? err.response.data.message
            : 'កំហុសបណ្តាញ។ សូមព្យាយាមម្តងទៀតនៅពេលក្រោយ។';

        setStatus(prev => ({
            ...prev,
            error: errorMessage,
            success: '',
            loading: false
        }));
    };

    const renderForm = () => (
        <form onSubmit={handleSubmit} className="form-container">
            <table className="table-container">
                <tbody>
                    <tr>
                        <td><label>លេខកូដ:</label></td>
                        <td><label>ឈ្មោះ: </label></td>
                    </tr>
                    <tr>
                        <td>
                            <input
                                type="text"
                                value={formData.id}
                                onChange={(e) => handleInputChange('id', e.target.value)}
                                disabled
                                placeholder="បង្កើតដោយស្វ័យប្រវត្តិ"
                                className="input-field"
                            />
                        </td>
                        <td>
                            <input
                                type="text"
                                value={formData.positionName}
                                onChange={(e) => handleInputChange('positionName', e.target.value)}
                                required
                                placeholder="បញ្ចូលឈ្មោះមុខតំណែង"
                                className="input-field"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td><label>នៅអង្គភាព: </label></td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <Dropdown
                                value={formData.departmentId}
                                onChange={(e) => handleInputChange('departmentId', e.target.value)}
                                options={[
                                    { value: 0, label: 'ជ្រើសរើសនាយកដ្ឋាន' },
                                    ...departments.map(dept => ({
                                        value: dept.id,
                                        label: dept.department_name
                                    }))
                                ]}
                                className="input-field"
                                required
                            />
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2} style={{ textAlign: 'right' }}>
                            <Button
                                onClick={() => navigate('/position')}
                                variant="close-button"
                                label="ចាកចេញ"
                            />
                            <Button
                                type="submit"
                                className="save-button"
                                label={status.loading ? 'កំពុងរក្សាទុក...' : 'រក្សាទុក'}
                                disabled={status.loading}
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </form>
    );

    return (
        <div className="container">
            <h2>មុខតំណែង</h2>
            {data ? renderForm() : <p>មិនមានទិន្នន័យមុខតំណែង។</p>}
            {status.error && (
                <div className="error-message-container">
                    <p className="error-message">{status.error}</p>
                </div>
            )}
            {status.success && <p className="success-message">{status.success}</p>}
        </div>
    );
}

export default EditPosition;
