import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from 'react-router-dom';
import { FaUser, FaCaretDown, FaRegBuilding, FaUserShield, FaHouseUser } from 'react-icons/fa';
import { isAuthenticated, handleLogout } from './pages/Auth/Auth';
import './App.css';
import Login from './pages/Auth/Login';
import logo from './file/image/gdt_logo.png';

// User Management
import User from './pages/User/User';
import EditUser from './pages/User/NewEdit/EditUser';
import ChangePassword from './pages/User/NewEdit/ChangePassword';
import NewUser from './pages/User/NewEdit/NewUser';

// Department Management
import Department from './pages/Department/Department';
import NewDepartment from './pages/Department/NewEdit/NewDepartment';
import EditDepartment from './pages/Department/NewEdit/EditDepartment';

// Position Management
import Position from './pages/Position/Position';
import NewPosition from './pages/Position/NewEdit/NewPosition';
import EditPosition from './pages/Position/NewEdit/EditPosition';

// Participant Management
import Participant from './pages/Participant/Participant';
import ViewParticipant from './pages/Participant/View/ViewParticipant';
import NewParticipant from './pages/Participant/NewEdit/NewParticipant';
import EditParticipant from './pages/Participant/NewEdit/EditPartipant';

// Schedule Management
import Schedule from './pages/Schedule/Schedule';
import ViewSchedule from './pages/Schedule/ViewSchedule';
import NewSchedule from './pages/Schedule/NewEdit/NewSchedule';
import EditSchedule from './pages/Schedule/NewEdit/EditSchedule';
import AddMeetingParticipant from './pages/Schedule/NewEdit/AddMeetingParticipant';
import AddMeetingDocument from './pages/Schedule/NewEdit/AddMeetingDocument';

// Room Management
import Room from './pages/Room/Room';
import NewRoom from './pages/Room/NewEdit/NewRoom';
import EditRoom from './pages/Room/NewEdit/EditRoom';

const moment = require('moment');
require('@thyrith/momentkh')(moment);

function App() {
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const permission_role = localStorage.getItem('permission-role');
  const currentUser = localStorage.getItem('name');

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const today = moment();
  const lunarDate = today.toLunarDate ? today.toLunarDate() : "Lunar date function not available";

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
  const handleItemClick = () => setDropdownOpen(false);

  return (
    <Router future={{ v7_startTransition: true, v7_relativeSplatPath: true }}>
      <div className="app-container">
        {isAuthenticated() && (
          <>
            <header className="app-title">
              <div className="title-logo">
                <img src={logo} alt="Logo" />
              </div>
              <div className='title-left'>
                <h1 style={{fontFamily: 'Khmer Moul'}}>នាយកដ្ឋានគ្រប់គ្រងអ្នកជាប់ពន្ធធំ</h1>
                <h3>LARGE TAXPAYER DEPARTMENT</h3>
              </div>
              <div className='title-right'>
                <h4 className='title-date-upper'>
                  {lunarDate}<br />
                  {currentDateTime.toLocaleString()}<br />
                  Current Login: {currentUser}
                </h4>
              </div>
            </header>

            <nav className="top-nav">
              <div className="nav-left">
                <ul>
                  <li><Link to="/schedule">កាលវិភាគប្រជុំ</Link></li>
                  <li><Link to="/participant">អ្នកចូលរួម</Link></li>
                  {(permission_role === '1' || permission_role === '2') && (
                    <li className="dropdown">
                      <span onClick={toggleDropdown} className="dropdown-button">
                        ការគ្រប់គ្រង&nbsp;<FaCaretDown />
                      </span>
                      {dropdownOpen && (
                        <ul className="dropdown-menu">
                          <li><Link to="/user-management" onClick={handleItemClick}><FaUserShield /> អ្នកប្រើប្រាស់</Link></li>
                          <li><Link to="/room" onClick={handleItemClick}><FaHouseUser /> បន្ទប់</Link></li>
                          <li><Link to="/position" onClick={handleItemClick}><FaUser /> ឋានះ ឫតួនាទី</Link></li>
                          <li><Link to="/department" onClick={handleItemClick}><FaRegBuilding /> នៅអង្គភាព</Link></li>
                        </ul>
                      )}
                    </li>
                  )}
                </ul>
              </div>
              <div className="nav-right">
                <button onClick={handleLogout} className="logout-button">Logout</button>
              </div>
            </nav>
          </>
        )}

        <main className="content">
          <Routes>
            <Route path="/" element={isAuthenticated() ? <Schedule /> : <Login />} />
            
            {/* User Management Routes */}
            <Route path="/user-management" element={isAuthenticated() ? <User /> : <Navigate to="/" />} />
            <Route path="/edit-user" element={isAuthenticated() ? <EditUser /> : <Navigate to="/" />} />
            <Route path="/new-user" element={isAuthenticated() ? <NewUser /> : <Navigate to="/" />} />
            <Route path="/change-password" element={isAuthenticated() ? <ChangePassword /> : <Navigate to="/" />} />
            
            {/* Position Routes */}
            <Route path="/position" element={isAuthenticated() ? <Position /> : <Navigate to="/" />} />
            <Route path="/new-position" element={isAuthenticated() ? <NewPosition /> : <Navigate to="/" />} />
            <Route path="/edit-position" element={isAuthenticated() ? <EditPosition /> : <Navigate to="/" />} />
            
            {/* Department Routes */}
            <Route path="/department" element={isAuthenticated() ? <Department /> : <Navigate to="/" />} />
            <Route path="/new-department" element={isAuthenticated() ? <NewDepartment /> : <Navigate to="/" />} />
            <Route path="/edit-department" element={isAuthenticated() ? <EditDepartment /> : <Navigate to="/" />} />
            
            {/* Participant Routes */}
            <Route path="/participant" element={isAuthenticated() ? <Participant /> : <Navigate to="/" />} />
            <Route path="/view-participant" element={isAuthenticated() ? <ViewParticipant /> : <Navigate to="/" />} />
            <Route path="/new-participant" element={isAuthenticated() ? <NewParticipant /> : <Navigate to="/" />} />
            <Route path="/edit-participant" element={isAuthenticated() ? <EditParticipant /> : <Navigate to="/" />} />
            
            {/* Schedule Routes */}
            <Route path="/schedule" element={isAuthenticated() ? <Schedule /> : <Navigate to="/" />} />
            <Route path="/view-schedule" element={isAuthenticated() ? <ViewSchedule /> : <Navigate to="/" />} />
            <Route path="/new-schedule" element={isAuthenticated() ? <NewSchedule /> : <Navigate to="/" />} />
            <Route path="/edit-schedule" element={isAuthenticated() ? <EditSchedule /> : <Navigate to="/" />} />
            <Route path="/add-participant" element={isAuthenticated() ? <AddMeetingParticipant /> : <Navigate to="/" />} />
            <Route path="/add-meeting-document" element={isAuthenticated() ? <AddMeetingDocument /> : <Navigate to="/" />} />
            
            {/* Room Routes */}
            <Route path="/room" element={isAuthenticated() ? <Room /> : <Navigate to="/" />} />
            <Route path="/new-room" element={isAuthenticated() ? <NewRoom /> : <Navigate to="/" />} />
            <Route path="/edit-room" element={isAuthenticated() ? <EditRoom /> : <Navigate to="/" />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;
