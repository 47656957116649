// Button.js
import React from 'react';
import PropTypes from 'prop-types';
import './Button.css';

const BUTTON_DEFAULTS = {
  className: '',
  label: 'Click Me',
  variant: 'default', 
  type: 'button'
};

const Button = ({
  onClick,
  className = BUTTON_DEFAULTS.className,
  label = BUTTON_DEFAULTS.label,
  variant = BUTTON_DEFAULTS.variant,
  type = BUTTON_DEFAULTS.type
}) => {
  const buttonClasses = ['btn', variant, className]
    .filter(Boolean)
    .join(' ');

  return (
    <button 
      onClick={onClick}
      className={buttonClasses}
      type={type}
    >
      {label}
    </button>
  );
};

Button.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['default', 'new-button', 'edit-button', 'delete-button', 'close-button']),
  type: PropTypes.oneOf(['button', 'submit', 'reset'])
};

export default Button;
