import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../../../Global-Form.css'
import Button from '../../../components/Button';

function NewDepartment() {
    const urlAPI = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    const [department, setDepartment] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(''); // Added missing success state
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        
        try {
            const token = localStorage.getItem('token');
            
            await axios.post(
                `${urlAPI}/department/create`,
                { department },
                { headers: { Authorization: `Bearer ${token}` } }
            );

            handleSuccess();

        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    const handleSuccess = () => {
        setError('');
        setSuccess('បង្កើតបានជោគជ័យ!'); // Added success message
        setTimeout(() => {
            navigate('/department');
        }, 1000);
    };

    const handleError = (err) => {
        const errorMessage = err.response?.status === 400
            ? err.response.data.message
            : 'បណ្តាញមានបញ្ហា។ សូមព្យាយាមម្តងទៀតនៅពេលក្រោយ។';
        setError(errorMessage);
    };

    const renderInput = () => (
        <input
            type="text"
            value={department}
            onChange={(e) => setDepartment(e.target.value)}
            required
            placeholder="បញ្ចូលឈ្មោះអង្គភាព"
            className="input-field"
        />
    );

    const renderButtons = () => (
        <td colSpan={2} style={{ textAlign: 'right' }}>
            <Button
                onClick={() => navigate('/department')}
                variant="close-button"
                label="ចាកចេញ"
            />
            <Button
                type="submit"
                variant="save-button"
                label={loading ? 'កំពុងបង្កើត...' : 'បង្កើត'}
                disabled={loading}
            />
        </td>
    );

    return (
        <div className="container">
            <h2>អង្គភាពថ្មី</h2>
            {error && (
                <div className="error-message-container">
                    <p className="error-message">{error}</p>
                </div>
            )}
            {success && <p className="success-message">{success}</p>}
            
            <form onSubmit={handleSubmit} className="form-container">
                <table className="table-container">
                    <tbody>
                        <tr>
                            <td><label>ឈ្មោះ: </label></td>
                        </tr>
                        <tr>
                            <td>{renderInput()}</td>
                        </tr>
                        <tr>
                            {renderButtons()}
                        </tr>
                    </tbody>
                </table>
            </form>
        </div>
    );
}

export default NewDepartment;
