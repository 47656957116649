import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './../../../Global-Form.css';
import Button from '../../../components/Button';
import Dropdown from '../../../components/Dropdown';

function NewUser() {
  const navigate = useNavigate();
  const urlAPI = process.env.REACT_APP_API_URL;

  const [formData, setFormData] = useState({
    username: '',
    name: '',
    password: '',
    participant: 0,
    permissionRole: 0
  });

  const [state, setState] = useState({
    error: null,
    success: null,
    loading: false,
    participants: [],
    permissionRoles: []
  });

  const handleInputChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const validateForm = () => {
    const { username, password, participant, permissionRole } = formData;
    if (!username || !password || participant === 0 || permissionRole === 0) {
      setState(prev => ({
        ...prev,
        error: 'សូមបំពេញគ្រប់ប្រអប់ទាំងអស់ឱ្យបានត្រឹមត្រូវ។'
      }));
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setState(prev => ({ ...prev, loading: true }));

    try {
      const token = localStorage.getItem('token');
      const { name, username, password, participant, permissionRole } = formData;

      await axios.post(
        `${urlAPI}/user/create`,
        {
          fullName: name,
          userName: username,
          password,
          participantId: participant,
          permissionRole
        },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );

      setState(prev => ({
        ...prev,
        success: 'អ្នកប្រើប្រាស់ត្រូវបានបង្កើតដោយជោគជ័យ!',
        error: null,
        loading: false
      }));

      setTimeout(() => navigate('/user-management'), 1000);

    } catch (err) {
      setState(prev => ({
        ...prev,
        loading: false,
        error: err.response?.status === 400
          ? err.response.data.message
          : 'បណ្តាញមានបញ្ហា។ សូមព្យាយាមម្តងទៀតនៅពេលក្រោយ។',
        success: null
      }));
    }
  };

  const fetchData = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const headers = { headers: { Authorization: `Bearer ${token}` } };

      const [participantResponse, userRoleResponse] = await Promise.all([
        axios.get(`${urlAPI}/participant/gets`, headers),
        axios.get(`${urlAPI}/user/gets/user-roles`, headers)
      ]);

      setState(prev => ({
        ...prev,
        participants: participantResponse.data.data,
        permissionRoles: userRoleResponse.data.data
      }));

    } catch (err) {
      setState(prev => ({
        ...prev,
        error: 'មិនអាចទាញយកទិន្នន័យបាន'
      }));
    }
  }, [urlAPI]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const { error, success, loading, participants, permissionRoles } = state;
  const { name, username, password, participant, permissionRole } = formData;

  return (
    <div className="container">
      <h2>ព័ត៌មានអ្នកប្រើប្រាស់</h2>

      {error && (
        <div className="error-message-container">
          <p className="error-message">{error}</p>
        </div>
      )}
      {success && <p className="success-message">{success}</p>}

      <form onSubmit={handleSubmit} className="form-container">
        <table className="table-container">
          <tbody>
            <tr>
              <td><label>ឈ្មោះ ពេញ:</label></td>
              <td><label>ឈ្មោះ អ្នកប្រើប្រាស់:</label></td>
            </tr>
            <tr>
              <td>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => handleInputChange('name', e.target.value)}
                  required
                  placeholder="បញ្ចូលឈ្មោះពេញ"
                  className="input-field"
                />
              </td>
              <td>
                <input
                  type="text"
                  value={username}
                  onChange={(e) => handleInputChange('username', e.target.value)}
                  required
                  placeholder="បញ្ចូលឈ្មោះអ្នកប្រើប្រាស់"
                  className="input-field"
                />
              </td>
            </tr>

            <tr>
              <td><label>លេខកូដ សំងាត់:</label></td>
              <td><label>អ្នកចូលរួម:</label></td>
            </tr>
            <tr>
              <td>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => handleInputChange('password', e.target.value)}
                  required
                  placeholder="បញ្ចូលលេខកូដសំងាត់"
                  className="input-field"
                />
              </td>
              <td>
                <Dropdown
                  value={participant}
                  onChange={(e) => handleInputChange('participant', e.target.value)}
                  options={[
                    { value: 0, label: 'ជ្រើសរើសអ្នកចូលរួម' },
                    ...participants.map(({ id, participant_name }) => ({
                      value: id,
                      label: participant_name
                    }))
                  ]}
                  className="input-field-dropdown"
                  required
                />
              </td>
            </tr>
            <tr>
              <td><label>តួនាទី:</label></td>
            </tr>
            <tr>
              <td>
                <Dropdown
                  value={permissionRole}
                  onChange={(e) => handleInputChange('permissionRole', e.target.value)}
                  options={[
                    { value: 0, label: 'ជ្រើសរើសតួនាទី' },
                    ...permissionRoles.map(({ id, name }) => ({
                      value: id,
                      label: name
                    }))
                  ]}
                  className="input-field-dropdown"
                  required
                />
              </td>
            </tr>
            <tr>
              <td colSpan={2} style={{ textAlign: 'right' }}>
                <Button
                  onClick={() => navigate('/user-management')}
                  variant="close-button"
                  label="ចាកចេញ"
                />
                <Button
                  type="submit"
                  className="save-button"
                  label={loading ? 'កំពុងបង្កើត...' : 'បង្កើត'}
                  disabled={loading}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </div>
  );
}

export default NewUser;
