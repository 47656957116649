import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './../../../Global-Form.css';
import Button from '../../../components/Button';
import Dropdown from '../../../components/Dropdown';

function NewPosition() {
  const urlAPI = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    positionName: '',
    departmentId: ''
  });
  const [departments, setDepartments] = useState([]);
  const [status, setStatus] = useState({
    error: null,
    success: null,
    loading: false
  });

  const fetchDepartments = useCallback(async () => {
    setStatus(prev => ({ ...prev, loading: true }));
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${urlAPI}/department/gets`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setDepartments(response.data.data);
      setStatus(prev => ({ ...prev, error: null }));
    } catch (err) {
      const errorMessage = err.response?.status === 400
        ? err.response.data.message
        : 'បណ្តាញមានបញ្ហា។ សូមព្យាយាមម្តងទៀតនៅពេលក្រោយ។';
      setStatus(prev => ({ ...prev, error: errorMessage }));
    } finally {
      setStatus(prev => ({ ...prev, loading: false }));
    }
  }, [urlAPI]);

  const handleInputChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  useEffect(() => {
    fetchDepartments();
  }, [fetchDepartments, urlAPI]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus(prev => ({ ...prev, loading: true }));

    try {
      const token = localStorage.getItem('token');
      await axios.post(
        `${urlAPI}/position/create`,
        formData,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );

      setStatus({
        success: 'មុខតំណែងត្រូវបានបង្កើតដោយជោគជ័យ!',
        error: null,
        loading: false
      });

      setTimeout(() => {
        navigate('/position');
      }, 1000);
    } catch (err) {
      const errorMessage = err.response?.status === 400
        ? err.response.data.message
        : 'បណ្តាញមានបញ្ហា។ សូមព្យាយាមម្តងទៀតនៅពេលក្រោយ។';

      setStatus({
        error: errorMessage,
        success: null,
        loading: false
      });
    }
  };

  const renderForm = () => (
    <form onSubmit={handleSubmit} className="form-container">
      <table className="table-container">
        <tbody>
          <tr>
            <td><label>ឈ្មោះ: </label></td>
          </tr>
          <tr>
            <td>
              <input
                type="text"
                value={formData.positionName}
                onChange={(e) => handleInputChange('positionName', e.target.value)}
                required
                placeholder="បញ្ចូលមុខតំណែង"
                className="input-field"
              />
            </td>
          </tr>
          <tr>
            <td><label>នៅអង្គភាព: </label></td>
          </tr>
          <tr>
            <td colSpan={2}>
              <Dropdown
                value={formData.departmentId}
                onChange={(e) => handleInputChange('departmentId', e.target.value)}
                options={[
                  { value: '', label: 'ជ្រើសរើសនាយកដ្ឋាន' },
                  ...departments.map((dept) => ({
                    value: dept.id,
                    label: dept.department_name
                  }))
                ]}
                className="input-field-dropdown"
                required
              />
            </td>
          </tr>
          <tr>
            <td colSpan={2} style={{ textAlign: 'right' }}>
              <Button
                onClick={() => navigate('/position')}
                variant="close-button"
                label="ចាកចេញ"
              />
              <Button
                type="submit"
                className="save-button"
                label={status.loading ? 'កំពុងបង្កើត...' : 'បង្កើត'}
                disabled={status.loading}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  );

  return (
    <div className="container">
      <h2>មុខតំណែងថ្មី</h2>

      {status.error && (
        <div className="error-message-container">
          <p className="error-message">{status.error}</p>
        </div>
      )}

      {status.success && <p className="success-message">{status.success}</p>}

      {status.loading ? <p>កំពុងផ្ទុកនាយកដ្ឋាន...</p> : renderForm()}
    </div>
  );
}

export default NewPosition;
