import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './ViewSchedule.css';
import Button from '../../components/Button';
import { formatDateMMDDYYYYHHMMSS } from '../../utils';

function ViewSchedule() {
  const location = useLocation();
  const navigate = useNavigate();
  const scheduleData = location.state?.data;
  const [activeTab, setActiveTab] = useState('schedule');
  const [participants, setParticipants] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    if (!scheduleData) {
      navigate('/schedule');
    }
  }, [scheduleData, navigate]);

  useEffect(() => {
    const fetchParticipants = async () => {
      if (activeTab === 'participants' && scheduleData?.id) {
        setLoading(true);
        try {
          const token = localStorage.getItem('token');
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/meeting-participant/get/${scheduleData.id}`,
            { headers: { Authorization: `Bearer ${token}` } }
          );
          setParticipants(response.data.data || []);
          setError(null);
        } catch (err) {
          setError('មានបញ្ហាក្នុងការទាញយកទិន្នន័យ');
          console.error('Error fetching participants:', err);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchParticipants();
  }, [activeTab, scheduleData?.id]);

  useEffect(() => {
    const fetchDocuments = async () => {
      if (activeTab === 'documents' && scheduleData?.id) {
        setLoading(true);
        try {
          const token = localStorage.getItem('token');
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/meeting-document/get/${scheduleData.id}`,
            { headers: { Authorization: `Bearer ${token}` } }
          );
          setDocuments(response.data.data || []);
          setError(null);
        } catch (err) {
          setError('មានបញ្ហាក្នុងការទាញយកទិន្នន័យ');
          console.error('Error fetching documents:', err);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchDocuments();
  }, [activeTab, scheduleData?.id]);

  if (!scheduleData) return null;

  const renderScheduleTab = () => (
    <>
      <div className="detail-group">
        <label>ប្រភពមកពី:</label>
        <span>{scheduleData.from_name || 'គ្មាន'}</span>
      </div>

      <div className="detail-group">
        <label>លេខលិខិត:</label>
        <span>{scheduleData.letter_number || 'គ្មាន'}</span>
      </div>

      <div className="detail-group">
        <label>ថ្ងៃខែលិខិត:</label>
        <span>{formatDateMMDDYYYYHHMMSS(scheduleData.letter_date) || 'គ្មាន'}</span>
      </div>

      <div className="detail-group">
        <label>ទីតាំង:</label>
        <span>{scheduleData.place || 'គ្មាន'}</span>
      </div>

      <div className="detail-group">
        <label>ថ្ងៃខែចាប់ផ្តើម:</label>
        <span>{formatDateMMDDYYYYHHMMSS(scheduleData.start_date) || 'គ្មាន'}</span>
      </div>

      <div className="detail-group">
        <label>ថ្ងៃខែបញ្ចប់:</label>
        <span>{formatDateMMDDYYYYHHMMSS(scheduleData.end_date) || 'គ្មាន'}</span>
      </div>

      <div className="detail-group">
        <label>អ្នកដឹកនាំប្រជុំ:</label>
        <span>{scheduleData.participant_name || 'គ្មាន'}</span>
      </div>

      <div className="detail-group">
        <label>បន្ទប់ប្រជុំ:</label>
        <span>{scheduleData.room_name || 'គ្មាន'}</span>
      </div>

      <div className="detail-group">
        <label>ការជូនដំណឹង (គិតជាម៉ង):</label>
        <span>{scheduleData.alert || 'គ្មាន'}</span>
      </div>

      <div className="detail-group leader-group">
        <label>សម្រាប់ថ្នាក់ដឹកនាំ:</label>
        <span>{scheduleData.is_leader ? 'បាទ/ចាស' : 'ទេ'}</span>
      </div>

      <div className="detail-group">
        <label>ផ្សេងៗ:</label>
        <span>{scheduleData.other || 'គ្មាន'}</span>
      </div>

      <div className="detail-group">
        <label>ចំណងជើង:</label>
        <span>{scheduleData.title || 'គ្មាន'}</span>
      </div>

      <div className="detail-group description-group">
        <label>កម្មវត្ថុ:</label>
        <span>{scheduleData.description || 'គ្មាន'}</span>
      </div>

      <div className="detail-group">
        <label>អ្នកចូលរួម:</label>
        <span>{scheduleData.participant_names || 'គ្មាន'}</span>
      </div>
    </>
  );

  const renderParticipantTab = () => {
    if (loading) {
      return (
        <div className="loading-container">
          <div className="spinner"></div>
          <p className="loading-text">កំពុងផ្ទុក...</p>
        </div>
      );
    }

    if (error) {
      return (
        <div className="error-message">
          {error}
        </div>
      );
    }

    if (!participants.length) {
      return (
        <div className="no-data-message">
          មិនមានអ្នកចូលរួមប្រជុំ
        </div>
      );
    }

    return (
      <div className="participants-table-container">
        <table className="participants-table">
          <thead>
            <tr>
              <th>ល.រ</th>
              <th>ឈ្មោះអ្នកចូលរួម</th>
              <th>ភេទ</th>
              <th>ឈ្មោះខ្លី</th>
              <th>តួនាទី</th>
              <th>នាយកដ្ឋាន</th>
            </tr>
          </thead>
          <tbody>
            {participants.map((participant, index) => (
              <tr key={participant.id}>
                <td>{index + 1}</td>
                <td>{participant.participant_name || 'គ្មាន'}</td>
                <td>{participant.name || 'គ្មាន'}</td>
                <td>{participant.short_name || 'គ្មាន'}</td>
                <td>{participant.position_name || 'គ្មាន'}</td>
                <td>{participant.department_name || 'គ្មាន'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const renderDocumentTab = () => {
    if (loading) {
      return (
        <div className="loading-container">
          <div className="spinner"></div>
          <p className="loading-text">កំពុងផ្ទុក...</p>
        </div>
      );
    }

    if (error) {
      return (
        <div className="error-message">
          {error}
        </div>
      );
    }

    if (!documents.length) {
      return (
        <div className="no-data-message">
          មិនមានឯកសារប្រជុំ
        </div>
      );
    }

    return (
      <div className="documents-table-container">
        <table className="documents-table">
          <thead>
            <tr>
              <th>ល.រ</th>
              <th>ឈ្មោះឯកសារ</th>
              <th>លេខលិខិត</th>
              <th>លេខកូដលិខិត</th>
              <th>សកម្មភាព</th>
            </tr>
          </thead>
          <tbody>
            {documents.map((doc, index) => (
              <tr key={doc.id}>
                <td>{index + 1}</td>
                <td>{doc.document_name || 'គ្មាន'}</td>
                <td>{doc.letter_number || 'គ្មាន'}</td>
                <td>{doc.code_number || 'គ្មាន'}</td>
                <td>
                  <Button
                    className="view-button"
                    onClick={() => window.open(doc.document_path, '_blank')}
                    label="មើល"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="view-schedule-container">
      <h1 className="view-schedule-title">ព័ត៌មានលម្អិតកាលវិភាគ</h1>
      
      <div className="tabs">
        <button 
          className={`tab ${activeTab === 'schedule' ? 'active' : ''}`}
          onClick={() => setActiveTab('schedule')}
        >
          កាលវិភាគ
        </button>
        <button 
          className={`tab ${activeTab === 'participants' ? 'active' : ''}`}
          onClick={() => setActiveTab('participants')}
        >
          អ្នកចូលរួម
        </button>
        <button 
          className={`tab ${activeTab === 'documents' ? 'active' : ''}`}
          onClick={() => setActiveTab('documents')}
        >
          ឯកសារ
        </button>
      </div>

      <div className="tab-content">
        {activeTab === 'schedule' && renderScheduleTab()}
        {activeTab === 'participants' && renderParticipantTab()}
        {activeTab === 'documents' && renderDocumentTab()}
      </div>

      <div className="button-group">
        <Button
          onClick={() => navigate('/schedule')}
          className="close-button"
          label="ត្រឡប់ក្រោយ"
        />
      </div>
    </div>
  );
}

export default ViewSchedule;