import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '../../../components/Button';
import './ViewParticipant.css';

function ViewParticipant() {
  const location = useLocation();
  const navigate = useNavigate();
  const participant = location.state?.data;

  if (!participant) {
    return (
      <div className="view-participant-container">
        <div className="error-message-container">
          <div className="error-message">
            រកមិនឃើញទិន្នន័យអ្នកចូលរួម
          </div>
        </div>
        <div className="button-group">
          <Button
            onClick={() => navigate('/participant')}
            className="close-button"
            label="ត្រឡប់ក្រោយ"
          />
        </div>
      </div>
    );
  }

  const formatDate = (dateString) => {
    if (!dateString) return 'គ្មាន';
    return new Date(dateString).toLocaleDateString('km-KH', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  return (
    <div className="view-participant-container">
      <h2 className="view-participant-title">ព័ត៌មានលម្អិតអ្នកចូលរួម</h2>

      <div className="participant-content">
        <div className="detail-group">
          <label>ឈ្មោះពេញ:</label>
          <span>{participant.participant_name || 'គ្មាន'}</span>
        </div>

        <div className="detail-group">
          <label>ឈ្មោះកាត់:</label>
          <span>{participant.short_name || 'គ្មាន'}</span>
        </div>

        <div className="detail-group">
          <label>ភេទ:</label>
          <span>{participant.gender_name || 'គ្មាន'}</span>
        </div>

        <div className="detail-group">
          <label>ឋានះ ឫតួនាទី:</label>
          <span>{participant.position_name || 'គ្មាន'}</span>
        </div>

        <div className="detail-group">
          <label>នៅអង្គភាព:</label>
          <span>{participant.department_name || 'គ្មាន'}</span>
        </div>

        <div className="detail-group">
          <label>លេខទូរស័ព្ទ:</label>
          <span>{participant.phone || 'គ្មាន'}</span>
        </div>

        <div className="detail-group">
          <label>អ៊ីមែល:</label>
          <span>{participant.email || 'គ្មាន'}</span>
        </div>

        <div className="detail-group">
          <label>កាលបរិច្ឆេទបង្កើត:</label>
          <span>{formatDate(participant.created_date)}</span>
        </div>

        <div className="detail-group">
          <label>កាលបរិច្ឆេទកែប្រែ:</label>
          <span>{formatDate(participant.updated_date)}</span>
        </div>

        <div className="button-group">
          <Button
            onClick={() => navigate('/participant')}
            className="close-button"
            label="ត្រឡប់ក្រោយ"
          />
        </div>
      </div>
    </div>
  );
}

export default ViewParticipant; 