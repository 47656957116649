import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import Button from '../../../components/Button';
import './../../../Global-Form.css';
import './../../../Global.css';

const ERROR_MESSAGES = {
  NETWORK: 'បណ្តាញមានបញ្ហា។ សូមព្យាយាមម្តងទៀតនៅពេលក្រោយ។',
  ADD_CONFIRM: 'តើអ្នកប្រាកដថាចង់បន្ថែមឯកសារនេះទេ?',
  DELETE_CONFIRM: 'តើអ្នកប្រាកដថាចង់លុបឯកសារនេះទេ?',
  DELETE_SUCCESS: 'ឯកសារត្រូវបានលុបដោយជោគជ័យ។'
};

function AddMeetingDocument() {
  const urlAPI = process.env.REACT_APP_API_URL;
  const location = useLocation();
  const { data } = location.state || {};

  const [records, setRecords] = useState([]);
  const [formData, setFormData] = useState({
    documentName: '',
    letterNumber: '',
    codeNumber: '',
    documentFile: null
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: files ? files[0] : value
    }));
  };

  const resetForm = () => {
    setFormData({
      documentName: '',
      letterNumber: '',
      codeNumber: '',
      documentFile: null
    });
  };

  const fetchData = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };

      const response = await axios.get(
        `${urlAPI}/meeting-document/get/${data.id}`,
        { headers }
      );

      setRecords(response.data?.data || []);
      setError(null);
    } catch (err) {
      const errorMessage = err.response?.status === 400
        ? err.response.data.message
        : ERROR_MESSAGES.NETWORK;
      setError(errorMessage);
      setRecords([]);
    } finally {
      setLoading(false);
    }
  }, [urlAPI, data.id]);

  const addMeetingDocument = async () => {
    if (!window.confirm(ERROR_MESSAGES.ADD_CONFIRM)) return;

    setLoading(true);
    const form = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      form.append(key, value);
    });
    form.append('scheduleId', data.id);

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        `${urlAPI}/meeting-document/create`,
        form,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      await fetchData();
      setSuccess(response.data.message || 'ឯកសារត្រូវបានបន្ថែមដោយជោគជ័យ!');
      setError(null);
      resetForm();
    } catch (err) {
      setError(err.response?.data?.message || ERROR_MESSAGES.NETWORK);
      setSuccess(null);
    } finally {
      setLoading(false);
    }
  };

  const deleteMeetingDocument = async (id) => {
    if (!window.confirm(ERROR_MESSAGES.DELETE_CONFIRM)) return;

    try {
      const token = localStorage.getItem('token');
      await axios.delete(
        `${urlAPI}/meeting-document/delete/${id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      await fetchData();
      setSuccess(ERROR_MESSAGES.DELETE_SUCCESS);
      setError(null);
    } catch (err) {
      const errorMessage = err.response?.status === 400
        ? err.response.data.message
        : ERROR_MESSAGES.NETWORK;
      setError(errorMessage);
      setSuccess(null);
    }
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const renderForm = () => (
    <table className="table-container">
      <tbody>
        <tr>
          <td><label>ឈ្មោះឯកសារ:</label></td>
          <td>
            <input
              type="text"
              name="documentName"
              value={formData.documentName}
              onChange={handleInputChange}
              className="input-field"
              required
            />
          </td>
        </tr>
        <tr>
          <td><label>លេខលិខិត:</label></td>
          <td>
            <input
              type="text"
              name="letterNumber"
              value={formData.letterNumber}
              onChange={handleInputChange}
              className="input-field"
              required
            />
          </td>
        </tr>
        <tr>
          <td><label>លេខ​កូដ លិខិត:</label></td>
          <td>
            <input
              type="text"
              name="codeNumber"
              value={formData.codeNumber}
              onChange={handleInputChange}
              className="input-field"
              required
            />
          </td>
        </tr>
        <tr>
          <td><label>ឯកសារ:</label></td>
          <td>
            <input
              type="file"
              name="documentFile"
              onChange={handleInputChange}
              className="input-field"
              required
            />
          </td>
        </tr>
        <tr>
          <td colSpan="2">
            <Button
              onClick={addMeetingDocument}
              className="update-button"
              label={loading ? 'កំពុងរក្សាទុក...' : 'រក្សាទុក'}
              disabled={loading}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );

  const renderRecordsTable = () => (
    <form className="form-container">
      <table className="data-table">
        <thead>
          <tr>
            <th>ល.រ</th>
            <th>ឈ្មោះឯកសារ</th>
            <th>លេខលិខិត</th>
            <th>លេខ​កូដ លិខិត</th>
            <th>សកម្មភាព</th>
          </tr>
        </thead>
        <tbody>
          {records.map((record, index) => (
            <tr key={record.id}>
              <td>{index + 1}</td>
              <td>{record.document_name || 'គ្មាន'}</td>
              <td>{record.letter_number || 'គ្មាន'}</td>
              <td>{record.code_number || 'គ្មាន'}</td>
              <td>
                <Button
                  onClick={() => deleteMeetingDocument(record.id)}
                  className="delete-button"
                  label={loading ? 'កំពុងលុប...' : 'លុប'}
                  disabled={loading}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </form>
  );

  const renderLoading = () => (
    <div className="loading-container">
      <div className="spinner"></div>
      <p className="loading-text">កំពុងផ្ទុក...</p>
    </div>
  );

  return (
    <div className="container">
      <h2>បន្ថែមឯកសារកិច្ចប្រជុំ</h2>

      {error && (
        <div className="error-message-container">
          <p className="error-message">{error}</p>
        </div>
      )}
      {success && <p className="success-message">{success}</p>}

      {renderForm()}
      {records.length > 0 ? renderRecordsTable() : loading && renderLoading()}
    </div>
  );
}

export default AddMeetingDocument;
