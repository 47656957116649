import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './../../Global.css';
import Button from '../../components/Button';

const ROUTES = {
  edit: '/edit-user',
  password: '/change-password', 
  new: '/new-user'
};

const ROLES = {
  1: 'អ្នកគ្រប់គ្រង',
  2: 'មេក្រុម',
  3: 'អ្នកចូលរួម'
};

const ERROR_MESSAGE = 'បណ្តាញមានបញ្ហា។ សូមព្យាយាមម្តងទៀតនៅពេលក្រោយ។';
const DELETE_CONFIRM = 'តើអ្នកប្រាកដថាចង់លុបអ្នកប្រើប្រាស់នេះមែនទេ?';
const DELETE_SUCCESS = 'អ្នកប្រើប្រាស់ត្រូវបានលុបដោយជោគជ័យ។';

function User() {
  const urlAPI = process.env.REACT_APP_API_URL;
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [userRole, setUserRole] = useState(null);
  const navigate = useNavigate();

  const fetchUsers = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${urlAPI}/user/gets`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setUsers(response.data.data || []);
      setError('');

      const permissionRole = localStorage.getItem('permission-role');
      setUserRole(Number(permissionRole));
    } catch (err) {
      setError(err.response?.status === 400 ? err.response.data.message : ERROR_MESSAGE);
    } finally {
      setLoading(false);
    }
  }, [urlAPI]);

  const deleteUser = async (userId) => {
    if (!window.confirm(DELETE_CONFIRM)) return;

    try {
      const token = localStorage.getItem('token');
      await axios.delete(`${urlAPI}/user/delete/${userId}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      alert(DELETE_SUCCESS);
      fetchUsers();
    } catch (err) {
      setError(err.response?.status === 400 ? err.response.data.message : ERROR_MESSAGE);
    }
  };

  const handleNavigation = (user, action) => {
    const route = ROUTES[action];
    if (route) {
      navigate(route, user ? { state: { user } } : undefined);
    }
  };

  const getRoleName = (roleId) => ROLES[roleId] || 'មិនស្គាល់';

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  if (loading) {
    return (
      <div className="loading-container">
        <div className="spinner" />
        <p className="loading-text">កំពុងផ្ទុក...</p>
      </div>
    );
  }

  const renderTableRow = (user, index) => (
    <tr key={user.id}>
      <td>{index + 1}</td>
      <td>{user.full_name}</td>
      <td>{user.username}</td>
      <td>{user.password ? '****' : 'គ្មាន'}</td>
      <td>{getRoleName(user.permission_role)}</td>
      {userRole === 1 && (
        <td>
          <Button 
            onClick={() => handleNavigation(user, 'edit')} 
            className="update-button" 
            label="កែប្រែ" 
          />
        </td>
      )}
      <td>
        <Button 
          onClick={() => handleNavigation(user, 'password')} 
          className="update-button" 
          label="ផ្លាស់ប្តូរលេខកូដ" 
        />
      </td>
      {userRole === 1 && (
        <td>
          <Button 
            onClick={() => deleteUser(user.id)} 
            className="delete-button" 
            label="លុប" 
          />
        </td>
      )}
    </tr>
  );

  return (
    <div className="home-container">
      <h1>ព័ត៌មានអ្នកប្រើប្រាស់</h1>

      {userRole === 1 && (
        <Button 
          onClick={() => handleNavigation(null, 'new')} 
          className="btn update-button"
          label="បង្កើតថ្មី"
        />
      )}

      {error && (
        <div className="error-message-container">
          <p className="error-message">{error}</p>
        </div>
      )}

      {users.length > 0 ? (
        <table className="data-table">
          <thead>
            <tr>
              <th>ល.រ</th>
              <th>ឈ្មោះពេញ</th>
              <th>ឈ្មោះអ្នកប្រើប្រាស់</th>
              <th>លេខកូដ</th>
              <th>តួនាទី</th>
              <th colSpan={userRole === 1 ? 3 : 1}>សកម្មភាព</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => renderTableRow(user, index))}
          </tbody>
        </table>
      ) : (
        <p>មិនមានអ្នកប្រើប្រាស់។</p>
      )}
    </div>
  );
}

export default User;
