import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './../../../Global-Form.css';
import Button from '../../../components/Button';
import Dropdown from '../../../components/Dropdown';

function NewParticipant() {
  const urlAPI = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    id: '',
    participantName: '',
    shortName: '',
    email: '',
    phone: '',
    genderId: '',
    positionId: '',
    departmentId: ''
  });

  const [dropdownData, setDropdownData] = useState({
    genders: [],
    positions: [],
    departments: []
  });

  const [status, setStatus] = useState({
    error: null,
    success: null,
    loading: false
  });

  const handleInputChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const fetchInitialData = useCallback(async () => {
    setStatus(prev => ({ ...prev, loading: true }));
    try {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };

      const [deptResponse, posResponse, genResponse] = await Promise.all([
        axios.get(`${urlAPI}/department/gets`, { headers }),
        axios.get(`${urlAPI}/position/gets`, { headers }),
        axios.get(`${urlAPI}/gender/gets`, { headers })
      ]);

      setDropdownData({
        departments: deptResponse.data.data,
        positions: posResponse.data.data,
        genders: genResponse.data.data
      });

      setFormData(prev => ({ ...prev, id: null }));
      setStatus(prev => ({ ...prev, error: null }));

    } catch (err) {
      const errorMessage = err.response?.status === 400 
        ? err.response.data.message 
        : 'បណ្តាញមានបញ្ហា។ សូមព្យាយាមម្តងទៀតនៅពេលក្រោយ។';
      
      setStatus(prev => ({ ...prev, error: errorMessage }));
    } finally {
      setStatus(prev => ({ ...prev, loading: false }));
    }
  }, [urlAPI]);

  const handleDepartmentChange = async (selectedDepartmentId) => {
    handleInputChange('departmentId', selectedDepartmentId);

    if (!selectedDepartmentId) {
      setDropdownData(prev => ({ ...prev, positions: [] }));
      return;
    }

    try {
      const token = localStorage.getItem('token');
      const headers = { headers: { Authorization: `Bearer ${token}` } };

      const response = await axios.get(
        `${urlAPI}/position/get/${selectedDepartmentId}`, 
        headers
      );

      setDropdownData(prev => ({ ...prev, positions: response.data.data }));
      setStatus(prev => ({ ...prev, error: null }));

    } catch (err) {
      const errorMessage = err.response?.status === 400 
        ? err.response.data.message 
        : 'បណ្តាញមានបញ្ហា។ សូមព្យាយាមម្តងទៀតនៅពេលក្រោយ។';

      setStatus(prev => ({ ...prev, error: errorMessage }));
      setDropdownData(prev => ({ ...prev, positions: [] }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus(prev => ({ ...prev, loading: true }));

    try {
      const token = localStorage.getItem('token');
      
      await axios.post(
        `${urlAPI}/participant/create`,
        {
          participantName: formData.participantName,
          genderId: formData.genderId,
          shortName: formData.shortName,
          positionId: formData.positionId,
          departmentId: formData.departmentId,
          email: formData.email,
          phone: formData.phone
        },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );

      setStatus({
        success: 'អ្នកចូលរួមត្រូវបានបង្កើតដោយជោគជ័យ!',
        error: null,
        loading: false
      });

      setTimeout(() => navigate('/participant'), 2000);

    } catch (err) {
      const errorMessage = err.response?.status === 400 
        ? err.response.data.message 
        : 'បណ្តាញមានបញ្ហា។ សូមព្យាយាមម្តងទៀតនៅពេលក្រោយ។';

      setStatus({
        error: errorMessage,
        success: null,
        loading: false
      });
    }
  };

  useEffect(() => {
    fetchInitialData();
  }, [fetchInitialData]);

  const renderForm = () => (
    <form onSubmit={handleSubmit} className="form-container">
      <table className="table-container">
        <tbody>
          <tr>
            <td><label>លេខកូដ:</label></td>
            <td><label>ឈ្មោះពេញ: </label></td>
          </tr>
          <tr>
            <td>
              <input
                type="text"
                value={formData.id}
                disabled
                placeholder="លេខកូដបង្កើតដោយស្វ័យប្រវត្តិ"
                className="input-field"
              />
            </td>
            <td>
              <input
                type="text"
                value={formData.participantName}
                onChange={(e) => handleInputChange('participantName', e.target.value)}
                required
                placeholder="បញ្ចូលឈ្មោះអ្នកចូលរួម"
                className="input-field"
              />
            </td>
          </tr>
          <tr>
            <td><label>ភេទ: </label></td>
            <td><label>ឈ្មោះកាត់: </label></td>
          </tr>
          <tr>
            <td>
              <Dropdown
                value={formData.genderId}
                onChange={(e) => handleInputChange('genderId', e.target.value)}
                options={[
                  { value: '', label: 'ជ្រើសរើសភេទ' },
                  ...dropdownData.genders.map(gen => ({
                    value: gen.id,
                    label: gen.name,
                  }))
                ]}
                className="input-field-dropdown"
                required
              />
            </td>
            <td>
              <input
                type="text"
                value={formData.shortName}
                onChange={(e) => handleInputChange('shortName', e.target.value)}
                required
                placeholder="បញ្ចូលឈ្មោះកាត់"
                className="input-field"
              />
            </td>
          </tr>
          <tr>
            <td><label>នៅអង្គភាព: </label></td>
            <td><label>ឋានះ ឫតួនាទី: </label></td>
          </tr>
          <tr>
            <td>
              <Dropdown
                value={formData.departmentId}
                onChange={(e) => handleDepartmentChange(e.target.value)}
                options={[
                  { value: '', label: 'ជ្រើសរើសនាយកដ្ឋាន' },
                  ...dropdownData.departments.map(dept => ({
                    value: dept.id,
                    label: dept.department_name,
                  }))
                ]}
                className="input-field-dropdown"
                required
              />
            </td>
            <td>
              <Dropdown
                value={formData.positionId}
                onChange={(e) => handleInputChange('positionId', e.target.value)}
                options={[
                  { value: '', label: 'ជ្រើសរើសមុខតំណែង' },
                  ...dropdownData.positions.map(pos => ({
                    value: pos.id,
                    label: pos.position_name,
                  }))
                ]}
                className="input-field-dropdown"
                required
              />
            </td>
          </tr>
          <tr>
            <td><label>អ៊ីមែល: </label></td>
            <td><label>លេខទូរស័ព្ទ: </label></td>
          </tr>
          <tr>
            <td>
              <input
                type="text"
                value={formData.email}
                onChange={(e) => handleInputChange('email', e.target.value)}
                placeholder="បញ្ចូលអ៊ីមែល"
                className="input-field"
              />
            </td>
            <td>
              <input
                type="text"
                value={formData.phone}
                onChange={(e) => handleInputChange('phone', e.target.value)}
                placeholder="បញ្ចូលលេខទូរស័ព្ទ"
                className="input-field"
              />
            </td>
          </tr>
          <tr>
            <td colSpan={2} style={{ textAlign: 'right' }}>
              <Button
                onClick={() => navigate('/participant')}
                variant="close-button"
                label="ចាកចេញ"
              />
              <Button
                type="submit"
                className="save-button"
                label={status.loading ? 'កំពុងបង្កើត...' : 'បង្កើត'}
                disabled={status.loading}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  );

  return (
    <div className="container">
      <h2>អ្នកចូលរួម</h2>
      {status.error && <p className="error-message">{status.error}</p>}
      {status.success && <p className="success-message">{status.success}</p>}
      {status.loading ? <p>កំពុងផ្ទុកទិន្នន័យ...</p> : renderForm()}
    </div>
  );
}

export default NewParticipant;
