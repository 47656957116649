import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../../../Global-Form.css';
import Button from '../../../components/Button';

function NewRoom() {
  const urlAPI = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    room: '',
    error: null,
    success: null,
    loading: false
  });

  const { room, error, success, loading } = formData;

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      room: e.target.value
    });
  };

  const clearMessages = () => {
    setFormData(prev => ({
      ...prev,
      error: null,
      success: null
    }));
  };

  const handleCreateSuccess = () => {
    setFormData(prev => ({
      ...prev,
      room: '',
      success: 'បន្ទប់ត្រូវបានបង្កើតដោយជោគជ័យ!',
      error: null,
      loading: false
    }));

    setTimeout(() => {
      navigate('/room');
    }, 1000);
  };

  const handleCreateError = (err) => {
    const errorMessage = err.response?.status === 400
      ? err.response.data.message
      : 'បណ្តាញមានបញ្ហា។ សូមព្យាយាមម្តងទៀតនៅពេលក្រោយ។';

    setFormData(prev => ({
      ...prev,
      error: errorMessage,
      success: null,
      loading: false
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    clearMessages();

    try {
      const token = localStorage.getItem('token');
      
      setFormData(prev => ({...prev, loading: true}));

      const response = await axios.post(
        `${urlAPI}/room/create`,
        { roomName: room },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );

      if (response.status === 200) {
        handleCreateSuccess();
      }
    } catch (err) {
      handleCreateError(err);
    }
  };

  const renderError = () => (
    error && (
      <div className="error-message-container">
        <p className="error-message">{error}</p>
      </div>
    )
  );

  const renderSuccess = () => (
    success && <p className="success-message">{success}</p>
  );

  return (
    <div className="container">
      <h2>បន្ទប់ប្រជុំ ថ្មី</h2>
      {renderError()}
      {renderSuccess()}

      <form onSubmit={handleSubmit} className="form-container">
        <table className="table-container">
          <tbody>
            <tr>
              <td><label>ឈ្មោះ:</label></td>
            </tr>
            <tr>
              <td>
                <input
                  type="text"
                  value={room}
                  onChange={handleInputChange}
                  required
                  placeholder="បញ្ចូលឈ្មោះបន្ទប់"
                  className="input-field"
                />
              </td>
            </tr>

            <tr>
              <td colSpan={2} style={{ textAlign: 'right' }}>
                <Button
                  onClick={() => navigate('/room')}
                  variant="close-button"
                  label="ចាកចេញ"
                />
                <Button
                  type="submit"
                  className="save-button"
                  label={loading ? 'កំពុងបង្កើត...' : 'បង្កើត'}
                  disabled={loading}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </div>
  );
}

export default NewRoom;
