import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import '../../../Global-Form.css';
import axios from 'axios';
import Button from '../../../components/Button';
import './../../../Global.css';
import Dropdown from '../../../components/Dropdown';

const ERROR_MESSAGES = {
    NETWORK: 'បណ្តាញមានបញ្ហា។ សូមព្យាយាមម្តងទៀតនៅពេលក្រោយ។',
    ADD_CONFIRM: 'តើអ្នកប្រាកដថាចង់បន្ថែមអ្នកចូលរួមនេះទេ?',
    DELETE_CONFIRM: 'តើអ្នកប្រាកដថាចង់លុបអ្នកចូលរួមនេះទេ?',
    DELETE_SUCCESS: 'បានលុបអ្នកចូលរួមដោយជោគជ័យ។',
    ADD_SUCCESS: 'បានបន្ថែមអ្នកចូលរួមដោយជោគជ័យ!'
};

const NOTIFICATION_CONFIG = {
    TITLE: "ការរំលឹកកិច្ចប្រជុំ!",
    DELAY: 1000
};

const LoadingSpinner = () => (
    <div className="loading-container">
        <div className="spinner"></div>
        <p className="loading-text">កំពុងផ្ទុក...</p>
    </div>
);

const ErrorMessage = ({ message }) => (
    <div className="error-message-container">
        <p className="error-message">{message}</p>
    </div>
);

const SuccessMessage = ({ message }) => (
    <p className="success-message">{message}</p>
);

const ParticipantTable = ({ records, onDelete, loading }) => (
    <form className="form-container">
        <table className="data-table">
            <thead>
                <tr>
                    <th>ល.រ</th>
                    <th>ឈ្មោះអ្នកចូលរួម</th>
                    <th>ភេទ</th>
                    <th>ឈ្មោះខ្លី</th>
                    <th>តួនាទី</th>
                    <th>នាយកដ្ឋាន</th>
                    <th>សកម្មភាព</th>
                </tr>
            </thead>
            <tbody>
                {records.map((record, index) => (
                    <tr key={record.id}>
                        <td>{index + 1}</td>
                        <td>{record.participant_name || 'គ្មាន'}</td>
                        <td>{record.name || 'គ្មាន'}</td>
                        <td>{record.short_name || 'គ្មាន'}</td>
                        <td>{record.position_name || 'គ្មាន'}</td>
                        <td>{record.department_name || 'គ្មាន'}</td>
                        <td>
                            <Button
                                onClick={() => onDelete(record.id)}
                                className="delete-button"
                                label={loading ? 'កំពុងលុប...' : 'លុប'}
                                disabled={loading}
                            />
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    </form>
);

function AddMeetingParticipant() {
    const urlAPI = process.env.REACT_APP_API_URL;
    const location = useLocation();
    const { data } = location.state || {};

    const [records, setRecords] = useState([]);
    const [participant, setParticipant] = useState(data?.participant_id || '');
    const [participants, setParticipants] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const getAuthHeaders = () => ({
        Authorization: `Bearer ${localStorage.getItem('token')}`
    });

    const handleError = useCallback((err) => {
        const errorMessage = err.response?.status === 400
            ? err.response.data.message
            : ERROR_MESSAGES.NETWORK;
        setError(errorMessage);
        setSuccess(null);
    }, []);

    const fetchData = useCallback(async () => {
        try {
            const headers = getAuthHeaders();

            const [participantResponse, mpResponse] = await Promise.all([
                axios.get(`${urlAPI}/participant/gets`, { headers }),
                axios.get(`${urlAPI}/meeting-participant/get/${data.id}`, { headers })
                    .catch(err => err.response?.status === 400 ? { data: { data: [] } } : Promise.reject(err))
            ]);

            setParticipants(participantResponse.data.data);
            setRecords(mpResponse.data.data);
            setError(null);
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    }, [urlAPI, data.id, handleError]);

    const sendNotifications = async (token, userData) => {
        const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

        for (const user of userData) {
            await axios.post(
                `${urlAPI}/appv1/schedule-notification`,
                {
                    userId: user.id,
                    title: NOTIFICATION_CONFIG.TITLE,
                    body: data.title,
                    scheduleTime: data.alert,
                    data: data,
                },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            await delay(NOTIFICATION_CONFIG.DELAY);
        }
    };

    const addMeetingParticipant = async () => {
        if (!window.confirm(ERROR_MESSAGES.ADD_CONFIRM)) return;

        try {
            const token = localStorage.getItem('token');
            const headers = getAuthHeaders();

            const response = await axios.post(
                `${urlAPI}/meeting-participant/create`,
                { scheduleId: data.id, participantId: participant },
                { headers }
            );

            if (response.status === 200) {
                const userResponse = await axios.post(
                    `${urlAPI}/meeting-participant/get-user`,
                    { scheduleId: data.id, participantId: participant },
                    { headers }
                );

                if (userResponse.status === 200) {
                    await sendNotifications(token, userResponse.data.data);
                }
            }

            setSuccess(response.data.message || ERROR_MESSAGES.ADD_SUCCESS);
            setError(null);
            setParticipant('');
            await fetchData();
        } catch (err) {
            handleError(err);
        }
    };
    const deleteMeetingParticipant = async (id) => {
        if (!window.confirm(ERROR_MESSAGES.DELETE_CONFIRM)) return;

        try {
            const headers = getAuthHeaders();
            const token = localStorage.getItem('token');

            // Get user info before deleting
            const userResponse = await axios.post(
                `${urlAPI}/meeting-participant/get-user`,
                { scheduleId: data.id, participantId: id },
                { headers }
            );

            // Delete meeting participant
            const deleteResponse = await axios.delete(
                `${urlAPI}/meeting-participant/delete/${id}`,
                { headers }
            );

            if (deleteResponse.status === 200) {
                // Cancel scheduled notification if user data exists
                if (userResponse.data?.data?.length > 0) {
                    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

                    for (const user of userResponse.data.data) {
                        await axios.post(
                            `${urlAPI}/appv1/cancel-scheduled-notification`,
                            { userId: user.id },
                            { headers: { Authorization: `Bearer ${token}` } }
                        );
                        await delay(NOTIFICATION_CONFIG.DELAY);
                    }
                }

                setSuccess(ERROR_MESSAGES.DELETE_SUCCESS);
                setError(null);
                await fetchData();
            }
        } catch (err) {
            handleError(err);
        }
    };

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <div className="container">
            <h2>តារាង អ្នកចូលរួម</h2>

            {error && <ErrorMessage message={error} />}
            {success && <SuccessMessage message={success} />}

            <table className="table-container">
                <tbody>
                    <tr>
                        <td>
                            <Dropdown
                                value={participant}
                                onChange={(e) => setParticipant(e.target.value)}
                                options={[
                                    { value: '', label: 'ជ្រើសរើសអ្នកចូលរួម' },
                                    ...participants.map(p => ({
                                        value: p.id,
                                        label: p.participant_name
                                    }))
                                ]}
                                className="input-field-dropdown"
                                required
                            />
                        </td>
                        <td>
                            <Button
                                onClick={addMeetingParticipant}
                                className="update-button"
                                label="បន្ថែម"
                            />
                        </td>
                    </tr>
                </tbody>
            </table>

            {records.length > 0 ? (
                <ParticipantTable
                    records={records}
                    onDelete={deleteMeetingParticipant}
                    loading={loading}
                />
            ) : loading && <LoadingSpinner />}
        </div>
    );
}

export default AddMeetingParticipant;
