import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './../../../Global-Form.css';
import axios from 'axios';
import Button from '../../../components/Button';
import Dropdown from '../../../components/Dropdown';

function EditUser() {
    const location = useLocation();
    const navigate = useNavigate();
    const { user } = location.state || {};
    const urlAPI = process.env.REACT_APP_API_URL;

    const [formData, setFormData] = useState({
        username: user?.username || '',
        name: user?.full_name || '',
        permissionRole: user?.permission_role || 0,
        participant: user?.participant_id || 0
    });

    const [state, setState] = useState({
        error: '',
        success: '',
        loading: false,
        participants: [],
        permissionRoles: []
    });

    const handleInputChange = (field, value) => {
        setFormData(prev => ({
            ...prev,
            [field]: value
        }));
    };

    const validateForm = () => {
        const { participant, permissionRole } = formData;
        if (participant === 0 || permissionRole === 0) {
            setState(prev => ({
                ...prev,
                error: 'សូមជ្រើសរើសអ្នកចូលរួម និងតួនាទីទាំងពីរ។'
            }));
            return false;
        }
        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        setState(prev => ({ ...prev, loading: true }));

        try {
            const token = localStorage.getItem('token');
            const { name, username, participant, permissionRole } = formData;

            await axios.post(
                `${urlAPI}/user/update/${user.id}`,
                {
                    fullName: name,
                    userName: username,
                    participantId: participant,
                    permissionRole
                },
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );

            setState(prev => ({
                ...prev,
                success: 'អ្នកប្រើប្រាស់ត្រូវបានធ្វើបច្ចុប្បន្នភាពដោយជោគជ័យ!',
                error: '',
                loading: false
            }));

            setTimeout(() => navigate('/user-management'), 1000);

        } catch (err) {
            setState(prev => ({
                ...prev,
                loading: false,
                error: err.response?.status === 400
                    ? err.response.data.message
                    : 'បណ្តាញមានបញ្ហា។ សូមព្យាយាមម្តងទៀតនៅពេលក្រោយ។',
                success: ''
            }));
        }
    };

    const fetchData = useCallback(async () => {
        try {
            const token = localStorage.getItem('token');
            const headers = { headers: { Authorization: `Bearer ${token}` } };
            const [participantResponse, userRoleResponse] = await Promise.all([
                axios.get(`${urlAPI}/participant/gets`, headers),
                axios.get(`${urlAPI}/user/gets/user-roles`, headers)
            ]);

            setState(prev => ({
                ...prev,
                participants: participantResponse.data.data,
                permissionRoles: userRoleResponse.data.data
            }));

        } catch (err) {
            setState(prev => ({
                ...prev,
                error: err.response?.status === 400
                    ? err.response.data.message
                    : 'បណ្តាញមានបញ្ហា។ សូមព្យាយាមម្តងទៀតនៅពេលក្រោយ។'
            }));
        }
    }, [urlAPI]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const { username, name, participant, permissionRole } = formData;
    const { error, success, loading, participants, permissionRoles } = state;

    if (!user) {
        return <p>មិនមានទិន្នន័យអ្នកប្រើប្រាស់ទេ។</p>;
    }

    return (
        <div className="container">
            <h2>ព័ត៌មានអ្នកប្រើប្រាស់</h2>
            {error && (
                <div className="error-message-container">
                    <p className="error-message">{error}</p>
                </div>
            )}
            {success && <p className="success-message">{success}</p>}
            
            <form onSubmit={handleSubmit} className="form-container">
                <table className="table-container">
                    <tbody>
                        <tr>
                            <td><label>ឈ្មោះពេញ:</label></td>
                            <td><label>ឈ្មោះអ្នកប្រើប្រាស់:</label></td>
                        </tr>
                        <tr>
                            <td>
                                <input
                                    type="text"
                                    value={name}
                                    onChange={(e) => handleInputChange('name', e.target.value)}
                                    required
                                    placeholder="បញ្ចូលឈ្មោះពេញ"
                                    className="input-field"
                                />
                            </td>
                            <td>
                                <input
                                    type="text"
                                    value={username}
                                    onChange={(e) => handleInputChange('username', e.target.value)}
                                    required
                                    placeholder="បញ្ចូលឈ្មោះអ្នកប្រើប្រាស់"
                                    className="input-field"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td><label>អ្នកចូលរួម:</label></td>
                            <td><label>តួនាទី:</label></td>
                        </tr>
                        <tr>
                            <td>
                                <Dropdown
                                    value={participant}
                                    onChange={(e) => handleInputChange('participant', e.target.value)}
                                    options={[
                                        { value: 0, label: 'ជ្រើសរើសអ្នកចូលរួម' },
                                        ...participants.map(({ id, participant_name }) => ({
                                            value: id,
                                            label: participant_name
                                        }))
                                    ]}
                                    className="input-field-dropdown"
                                    required
                                />
                            </td>
                            <td>
                                <Dropdown
                                    value={permissionRole}
                                    onChange={(e) => handleInputChange('permissionRole', e.target.value)}
                                    options={[
                                        { value: 0, label: 'ជ្រើសរើសតួនាទី' },
                                        ...permissionRoles.map(({ id, name }) => ({
                                            value: id,
                                            label: name
                                        }))
                                    ]}
                                    className="input-field-dropdown"
                                    required
                                />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2} style={{ textAlign: 'right' }}>
                                <Button
                                    onClick={() => navigate('/user-management')}
                                    variant="close-button"
                                    label="ចាកចេញ"
                                />
                                <Button
                                    type="submit"
                                    className="save-button"
                                    label={loading ? 'កំពុងរក្សាទុក...' : 'រក្សាទុក'}
                                    disabled={loading}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </div>
    );
}

export default EditUser;
