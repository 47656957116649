import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './../../Global.css';
import Button from '../../components/Button';

function Room() {
  const urlAPI = process.env.REACT_APP_API_URL;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleError = (err) => {
    const errorMessage = err.response?.status === 400
      ? err.response.data.message
      : 'បណ្តាញមានបញ្ហា។ សូមព្យាយាមម្តងទៀតនៅពេលក្រោយ។';
    setError(errorMessage);
  };

  const fetchRoom = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setError('Token not found. Please login again.');
        setLoading(false);
        return;
      }

      const response = await axios.get(`${urlAPI}/room/gets`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      
      setData(response.data.data || []);
      setError('');
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  }, [urlAPI]);

  const deleteRoom = async (roomId) => {
    const confirmed = window.confirm('តើអ្នកប្រាកដថាចង់លុបបន្ទប់នេះមែនទេ?');
    if (!confirmed) return;

    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setError('Token not found. Please login again.');
        return;
      }

      await axios.delete(`${urlAPI}/room/delete/${roomId}`, {
        headers: { Authorization: `Bearer ${token}` }
      });

      alert('បន្ទប់ត្រូវបានលុបដោយជោគជ័យ។');
      fetchRoom();
    } catch (err) {
      handleError(err);
    }
  };

  const openModal = (data, action) => {
    const route = action === 'edit' ? '/edit-room' : '/new-room';
    const state = action === 'edit' ? { state: { data } } : undefined;
    navigate(route, state);
  };

  useEffect(() => {
    fetchRoom();
  }, [fetchRoom]);

  const renderLoadingSpinner = () => (
    <div className="loading-container">
      <div className="spinner"></div>
      <p className="loading-text">កំពុងផ្ទុក...</p>
    </div>
  );

  const renderError = () => (
    <div className="error-message-container">
      <p className="error-message">{error}</p>
    </div>
  );

  const renderRoomTable = () => (
    <table className="data-table">
      <thead>
        <tr>
          <th>លេខ.</th>
          <th>បន្ទប់</th>
          <th colSpan={2}>សកម្មភាព</th>
        </tr>
      </thead>
      <tbody>
        {data.map((room, index) => (
          <tr key={room.id}>
            <td>{index + 1}</td>
            <td>{room.name || 'គ្មាន'}</td>
            <td>
              <Button 
                onClick={() => openModal(room, 'edit')} 
                className="update-button" 
                label="កែប្រែ" 
              />
            </td>
            <td>
              <Button 
                onClick={() => deleteRoom(room.id)} 
                className="delete-button" 
                label="លុប" 
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  return (
    <div className="home-container">
      <h1>បន្ទប់ប្រជុំ</h1>

      <Button 
        onClick={() => openModal(null, 'new')} 
        className="update-button" 
        label="បង្កើតថ្មី" 
      />

      {loading && renderLoadingSpinner()}
      {error && renderError()}
      {!loading && data.length > 0 && renderRoomTable()}
      {!loading && data.length === 0 && <p>រកមិនឃើញបន្ទប់។</p>}
    </div>
  );
}

export default Room;
