import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './../../../Global-Form.css';
import axios from 'axios';
import Button from '../../../components/Button';
import Dropdown from '../../../components/Dropdown';
import AddMeetingParticipant from './AddMeetingParticipant';
import AddMeetingDocument from './AddMeetingDocument';
import { formatDateTimeMMDDYYYYHHMM } from '../../../utils';

function EditSchedule() {
    const urlAPI = process.env.REACT_APP_API_URL;
    const location = useLocation();
    const navigate = useNavigate();
    const { data } = location.state || {}; // Retrieve participant data from state

    // Local state to manage form inputs and feedback
    //const [id, setId] = useState(data ? data.id : '');
    const [letterNumber, setLetterNumber] = useState(data ? data.letter_number : ''); // This is fine
    const [letterDate, setLetterDate] = useState(data ? data.letter_date.split('T')[0] : '');
    const [place, setPlace] = useState(data ? data.place : '');
    const [startDate, setStartDate] = useState(data ? formatDateTimeMMDDYYYYHHMM(data.start_date) : '');
    const [endDate, setEndDate] = useState(data ? formatDateTimeMMDDYYYYHHMM(data.end_date) : '');
    const [alert, setAlert] = useState(data ? data.alert : '');
    const [other, setOther] = useState(data ? data.other : '');
    const [title, setTitle] = useState(data ? data.title : '');
    const [description, setDescription] = useState(data ? data.description : '');

    const [departmentId, setDepartment] = useState(data ? data.from_id : '');
    const [departmentIds, setDepartments] = useState([]);

    const [roomId, setRoom] = useState(data ? data.room_id : '');
    const [roomIds, setRooms] = useState([]);

    const [participant, setParticipant] = useState(data ? data.participant_id : '');
    const [participants, setParticipants] = useState([]);

    const [isLeader, setIsLeader] = useState(data ? data.is_leader : 0);

    const [error, setError] = useState(''); // Error state
    const [success, setSuccess] = useState(''); // Success message
    const [loading, setLoading] = useState(false);

    // Add new state for active tab
    const [activeTab, setActiveTab] = useState('schedule');

    // Fetch data when component mounts
    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem('token');
                const headers = { headers: { Authorization: `Bearer ${token}` } };

                // Using Promise.all to fetch all data simultaneously
                const [deptResponse, roomResponse, participantResponse] =
                    await Promise.all([
                        axios.get(
                            `${urlAPI}/department/gets`, headers
                        ),
                        axios.get(
                            `${urlAPI}/room/gets`, headers
                        ),
                        axios.get(
                            `${urlAPI}/participant/gets`, headers
                        )
                    ]);

                setDepartments(deptResponse.data.data);
                setRooms(roomResponse.data.data);
                setParticipants(participantResponse.data.data);
            } catch (err) {
                if (err.response && err.response.status === 400) {
                    setError(err.response.data.message); // Show specific error message from the backend
                } else {
                    setError('បណ្តាញមានបញ្ហា។ ��ូមព្យាយាមម្តងទៀតនៅពេលក្រោយ។');
                }
            }
        };
        fetchData();
    }, [urlAPI]); // Empty array ensures it only runs once on mount

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(''); // Clear previous error
        setSuccess(''); // Clear success message

        try {
            const token = localStorage.getItem('token');
            const scheduleId = data ? data.id : '';

            setLoading(true); // Start loading
            await axios.post(
                `${urlAPI}/schedule/update/${scheduleId}`,
                {
                    departmentId,
                    title,
                    description,
                    letterNumber,
                    letterDate,
                    place,
                    startDate,
                    endDate,
                    other,
                    alert,
                    roomId,
                    isLeader,
                    participantId: participant
                },
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );

            setSuccess('កាលវិភាគត្រូវបានធ្វើបច្ចុប្បន្នភាពដោយជោគជ័យ!');
            setError(''); // Clear previous errors
            setLoading(false); // Stop loading
            setTimeout(() => {
                navigate('/schedule');
            }, 1000);
        } catch (err) {
            setLoading(false); // Stop loading
            // Check if it's a 400 error and display the backend message
            if (err.response && err.response.status === 400) {
                setError(err.response.data.message); // Show specific error message from the backend
            } else {
                setError('បណ្តាញមានបញ្ហា។ សូមព្យាយាមម្តងទៀតនៅពេលក្រោយ។');
            }
        }
    };

    return (
        <div className="container">
            <h2>កាលវិភាគ</h2>
            {error && (
                <div className="error-message-container">
                    <p className="error-message">{error}</p>
                </div>
            )}
            {success && <p className="success-message">{success}</p>}

            {/* Add tab navigation */}
            <div className="tabs-container" style={tabsContainerStyle}>
                <button
                    style={{
                        ...tabStyle,
                        backgroundColor: activeTab === 'schedule' ? '#007bff' : '#f0f0f0',
                        color: activeTab === 'schedule' ? 'white' : 'black',
                        fontWeight: 'bold'
                    }}
                    onClick={() => setActiveTab('schedule')}
                >
                    កាលវិភាគ
                </button>
                <button
                    style={{
                        ...tabStyle,
                        backgroundColor: activeTab === 'participants' ? '#007bff' : '#f0f0f0',
                        color: activeTab === 'participants' ? 'white' : 'black',
                        fontWeight: 'bold'
                    }}
                    onClick={() => setActiveTab('participants')}
                >
                    អ្នកចូលរួមប្រជុំ
                </button>
                <button
                    style={{
                        ...tabStyle,
                        backgroundColor: activeTab === 'documents' ? '#007bff' : '#f0f0f0',
                        color: activeTab === 'documents' ? 'white' : 'black',
                        fontWeight: 'bold'
                    }}
                    onClick={() => setActiveTab('documents')}
                >
                    ឯកសារប្រជុំ
                </button>
            </div>

            {/* Render content based on active tab */}
            {activeTab === 'schedule' ? (
                // Your existing form code here
                data ? (
                    <form onSubmit={handleSubmit} className="form-container">
                        <table className="table-container">
                            <tbody>
                                <tr>
                                    <td><label>ប្រភពមកពី: </label></td>
                                    <td><label>លេខលិខិត: </label></td>
                                </tr>
                                <tr>
                                    <td>
                                        <Dropdown
                                            value={departmentId} // Ensure gender is handled properly
                                            onChange={(e) => setDepartment(e.target.value)} // Set department to the selected ID
                                            options={[
                                                { value: '', label: 'ជ្រើសរើសនាយកដ្ឋាន' },
                                                ...departmentIds.map((data) => ({
                                                    value: data.id,  // Use ID as the value
                                                    label: data.department_name, // Display department name
                                                }))
                                            ]}
                                            className="input-field-dropdown"
                                            required
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            value={letterNumber}
                                            onChange={(e) => setLetterNumber(e.target.value)}
                                            required
                                            placeholder="បញ្ចូលលេខលិខិត"
                                            className="input-field"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td><label>ថ្ងៃខែលិខិត:</label></td>
                                    <td><label>ទីតាំង: </label></td>
                                </tr>
                                <tr>
                                    <td>
                                        <input
                                            type="date"
                                            value={letterDate}
                                            onChange={(e) => setLetterDate(e.target.value)}
                                            required
                                            placeholder="បញ្ចូលថ្ងៃខែលិខិត"
                                            className="input-field"
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            value={place}
                                            onChange={(e) => setPlace(e.target.value)}
                                            required
                                            placeholder="បញ្ចូលទីតាំង"
                                            className="input-field"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td><label>ថ្ងៃខែចាប់ផ្តើម:</label></td>
                                    <td><label>ថ្ងៃខែបញ្ចប់: </label></td>
                                </tr>
                                <tr>
                                    <td>
                                        <input
                                            type="datetime-local"
                                            value={startDate}
                                            onChange={(e) => setStartDate(formatDateTimeMMDDYYYYHHMM(e.target.value))}
                                            required
                                            placeholder="បញ្ចូលថ្ងៃខែចាប់ផ្តើម"
                                            className="input-field"
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="datetime-local"
                                            value={endDate}
                                            onChange={(e) => setEndDate(formatDateTimeMMDDYYYYHHMM(e.target.value))}
                                            required
                                            placeholder="បញ្ចូលថ្ងៃខែបញ្ចប់"
                                            className="input-field"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td><label>អ្នកដឹកនាំប្រជុំ: </label></td>
                                    <td><label>បន្ទប់ប្រជុំ: </label></td>
                                </tr>
                                <tr>
                                    <td>
                                        <Dropdown
                                            value={participant}
                                            onChange={(e) => setParticipant(e.target.value)}
                                            options={[{
                                                value: '', label: 'ជ្រើសរើសអ្នកចូលរួម'
                                            },
                                            ...participants.map((data) =>
                                            ({
                                                value: data.id,
                                                label: data.participant_name,
                                            }))]}
                                            className="input-field-dropdown"
                                            required
                                        />
                                    </td>
                                    <td>
                                        <Dropdown
                                            value={roomId}
                                            onChange={(e) => setRoom(e.target.value)}
                                            options={[
                                                { value: '', label: 'ជ្រើសរើសបន្ទប់' },
                                                ...roomIds.map((data) => ({
                                                    value: data.id,
                                                    label: data.name,
                                                }))
                                            ]}
                                            className="input-field-dropdown"
                                            required
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td><label>ការជូនដំណឹង (គិតជាម៉ង): </label></td>
                                </tr>
                                <tr>
                                    <td>
                                        <input
                                            type="number"
                                            value={alert}
                                            onChange={(e) => setAlert(e.target.value)}
                                            required
                                            placeholder="បញ្ចូលការជូនដំណឹ��"
                                            className="input-field"
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="checkbox"
                                            id="isLeader"
                                            checked={isLeader} // Boolean state to track checkbox state
                                            onChange={(e) => setIsLeader(e.target.checked)} // Updates the state based on checked/unchecked
                                            className="input-field-checkbox"
                                        />
                                        <label htmlFor="isLeader">សម្រាប់ថ្នាក់ដឹកនាំ</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>ផ្សេងៗ: </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>
                                        <input
                                            type="text"
                                            value={other}
                                            onChange={(e) => setOther(e.target.value)}
                                            placeholder="បញ្ចូលផ្សេងៗ"
                                            className="input-field"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td><label>ចំណងជើង:</label></td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>
                                        <input
                                            type="text"
                                            value={title || ''}
                                            onChange={(e) => setTitle(e.target.value)}
                                            required
                                            placeholder="បញ្ចូលចំណងជើង"
                                            className="input-field"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td><label>កម្មវត្ថុ:</label></td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>
                                        <textarea
                                            value={description || ''}
                                            onChange={(e) => setDescription(e.target.value)}
                                            required
                                            placeholder="បញ្ចូលកម្មវត្ថុ"
                                            className="input-field"
                                            rows={4}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2} style={{ textAlign: 'right' }}>
                                        <Button
                                            onClick={() => navigate('/schedule')}
                                            variant="close-button"
                                            label="ចាកចេញ"
                                        />
                                        <Button
                                            type="submit"
                                            className="save-button"
                                            label={loading ? 'កំពុងរក្សាទុក...' : 'រក្សាទុក'}
                                            disabled={loading} // Disable button while loading
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </form>
                ) : (
                    <p>មិនមានទិន្នន័យកាលវិភាគទេ។</p>
                )
            ) : activeTab === 'participants' ? (
                <AddMeetingParticipant />
            ) : (
                <AddMeetingDocument />
            )}
        </div>
    );
}

// Add these styles at the bottom of the file
const tabsContainerStyle = {
    display: 'flex',
    gap: '10px',
    marginBottom: '20px'
};

const tabStyle = {
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    fontSize: '14px'
};

export default EditSchedule;
