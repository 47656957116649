import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './../../../Global-Form.css';
import axios from 'axios';
import Button from '../../../components/Button';
import Dropdown from '../../../components/Dropdown';

function EditParticipant() {
  const urlAPI = process.env.REACT_APP_API_URL;
  const location = useLocation();
  const navigate = useNavigate();
  const { data } = location.state || {};

  const [formData, setFormData] = useState({
    id: data?.id || '',
    participantName: data?.participant_name || '',
    shortName: data?.short_name || '',
    email: data?.email || '',
    phone: data?.phone || '',
    genderId: data?.gender_id || '',
    positionId: data?.position_id || '',
    departmentId: data?.department_id || ''
  });

  const [dropdownData, setDropdownData] = useState({
    genders: [],
    positions: [],
    departments: []
  });

  const [status, setStatus] = useState({
    error: '',
    success: '',
    loading: false
  });

  const fetchInitialData = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const headers = { headers: { Authorization: `Bearer ${token}` } };

      const [deptResponse, posResponse, genResponse] = await Promise.all([
        axios.get(`${urlAPI}/department/gets`, headers),
        axios.get(`${urlAPI}/position/gets`, headers),
        axios.get(`${urlAPI}/gender/gets`, headers)
      ]);

      setDropdownData({
        departments: deptResponse.data.data,
        positions: posResponse.data.data,
        genders: genResponse.data.data
      });
      setStatus(prev => ({ ...prev, error: '' }));
    } catch (err) {
      const errorMessage = err.response?.status === 400
        ? err.response.data.message
        : 'បណ្តាញមានបញ្ហា។ សូមព្យាយាមម្តងទៀតនៅពេលក្រោយ។';
      setStatus(prev => ({ ...prev, error: errorMessage }));
    }
  }, [urlAPI]);

  useEffect(() => {
    fetchInitialData();
  }, [fetchInitialData]);

  const handleDepartmentChange = async (selectedDepartmentId) => {
    setFormData(prev => ({ ...prev, departmentId: selectedDepartmentId }));

    if (!selectedDepartmentId) {
      setDropdownData(prev => ({ ...prev, positions: [] }));
      return;
    }

    try {
      const token = localStorage.getItem('token');
      const headers = { headers: { Authorization: `Bearer ${token}` } };
      const response = await axios.get(`${urlAPI}/position/get/${selectedDepartmentId}`, headers);

      setDropdownData(prev => ({ ...prev, positions: response.data.data }));
      setStatus(prev => ({ ...prev, error: '' }));
    } catch (err) {
      const errorMessage = err.response?.status === 400
        ? err.response.data.message
        : 'បណ្តាញមានបញ្ហា។ សូមព្យាយាមម្តងទៀតនៅពេលក្រោយ។';
      setStatus(prev => ({ ...prev, error: errorMessage }));
      setDropdownData(prev => ({ ...prev, positions: [] }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.departmentId) {
      setStatus(prev => ({ ...prev, error: 'សូមជ្រើសរើសនាយកដ្ឋានត្រឹមត្រូវ។' }));
      return;
    }

    try {
      const token = localStorage.getItem('token');
      setStatus(prev => ({ ...prev, loading: true }));

      await axios.post(
        `${urlAPI}/participant/update/${formData.id}`,
        formData,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      setStatus({
        success: 'អ្នកចូលរួមត្រូវបានធ្វើបច្ចុប្បន្នភាពដោយជោគជ័យ!',
        error: '',
        loading: false
      });

      setTimeout(() => navigate('/participant'), 1000);
    } catch (err) {
      const errorMessage = err.response?.status === 400
        ? err.response.data.message
        : 'បណ្តាញមានបញ្ហា។ សូមព្យាយាមម្តងទៀតនៅពេលក្រោយ។';

      setStatus({
        error: errorMessage,
        success: '',
        loading: false
      });
    }
  };

  const handleInputChange = (field, value) => {
    setFormData(prev => ({ ...prev, [field]: value }));
  };

  const renderFormField = (label, inputProps) => (
    <>
      <td><label>{label}</label></td>
      <td>
        {inputProps.type === 'dropdown' ? (
          <Dropdown
            {...inputProps}
            className="input-field-dropdown"
            required
          />
        ) : (
          <input
            {...inputProps}
            className="input-field"
          />
        )}
      </td>
    </>
  );

  return (
    <div className="container">
      <h2>អ្នកចូលរួម</h2>
      {status.error && <p className="error-message">{status.error}</p>}
      {status.success && <p className="success-message">{status.success}</p>}

      {data ? (
        <form onSubmit={handleSubmit} className="form-container">
          <table className="table-container">
            <tbody>
              <tr>
                {renderFormField('លេខកូដ:', {
                  type: 'text',
                  value: formData.id,
                  onChange: (e) => handleInputChange('id', e.target.value),
                  disabled: true,
                  placeholder: 'លេខកូដបង្កើតដោយស្វ័យប្រវត្តិ'
                })}
              </tr>
              <tr>
                {renderFormField('ឈ្មោះពេញ:', {
                  type: 'text',
                  value: formData.participantName,
                  onChange: (e) => handleInputChange('participantName', e.target.value),
                  required: true,
                  placeholder: 'បញ្ចូលឈ្មោះអ្នកចូលរួម'
                })}
              </tr>
              <tr>
                {renderFormField('ភេទ:', {
                  type: 'dropdown',
                  value: formData.genderId,
                  onChange: (e) => handleInputChange('genderId', e.target.value),
                  options: [
                    { value: '', label: 'ជ្រើសរើសភេទ' },
                    ...dropdownData.genders.map(gen => ({
                      value: gen.id,
                      label: gen.name
                    }))
                  ]
                })}
              </tr>
              <tr>
                {renderFormField('ឈ្មោះកាត់:', {
                  type: 'text',
                  value: formData.shortName,
                  onChange: (e) => handleInputChange('shortName', e.target.value),
                  required: true,
                  placeholder: 'បញ្ចូលឈ្មោះកាត់'
                })}
              </tr>
              <tr>
                {renderFormField('នៅអង្គភាព:', {
                  type: 'dropdown',
                  value: formData.departmentId,
                  onChange: (e) => handleDepartmentChange(e.target.value),
                  options: [
                    { value: '', label: 'ជ្រើសរើសនាយកដ្ឋាន' },
                    ...dropdownData.departments.map(dept => ({
                      value: dept.id,
                      label: dept.department_name
                    }))
                  ]
                })}
              </tr>
              <tr>
                {renderFormField('ឋានះ ឫតួនាទី:', {
                  type: 'dropdown',
                  value: formData.positionId,
                  onChange: (e) => handleInputChange('positionId', e.target.value),
                  options: [
                    { value: '', label: 'ជ្រើសរើសមុខតំណែង' },
                    ...dropdownData.positions.map(pos => ({
                      value: pos.id,
                      label: pos.position_name
                    }))
                  ]
                })}
              </tr>
              <tr>
                {renderFormField('អ៊ីមែល:', {
                  type: 'email',
                  value: formData.email,
                  onChange: (e) => handleInputChange('email', e.target.value),
                  placeholder: 'បញ្ចូលអ៊ីមែល'
                })}
              </tr>
              <tr>
                {renderFormField('លេខទូរស័ព្ទ:', {
                  type: 'tel',
                  value: formData.phone,
                  onChange: (e) => handleInputChange('phone', e.target.value),
                  placeholder: 'បញ្ចូលលេខទូរស័ព្ទ'
                })}
              </tr>
              <tr>
                <td colSpan={2} style={{ textAlign: 'right' }}>
                  <Button
                    onClick={() => navigate('/participant')}
                    variant="close-button"
                    label="ចាកចេញ"
                  />
                  <Button
                    type="submit"
                    className="save-button"
                    label={status.loading ? 'កំពុងរក្សាទុក...' : 'រក្សាទុក'}
                    disabled={status.loading}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      ) : (
        <p>មិនមានទិន្នន័យអ្នកចូលរួម។</p>
      )}
    </div>
  );
}

export default EditParticipant;
