import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import './../../../Global-Form.css';
import Button from '../../../components/Button';

function ChangePassword() {
    const navigate = useNavigate();
    const { state } = useLocation();
    const urlAPI = process.env.REACT_APP_API_URL;

    const [formData, setFormData] = useState({
        currentPassword: '',
        newPassword: '', 
        confirmPassword: ''
    });

    const [status, setStatus] = useState({
        error: null,
        success: null,
        loading: false
    });

    const handleInputChange = (field, value) => {
        setFormData(prev => ({
            ...prev,
            [field]: value
        }));
        // Clear error when user starts typing
        if (status.error) {
            setStatus(prev => ({ ...prev, error: null }));
        }
    };

    const validateForm = () => {
        const { currentPassword, newPassword, confirmPassword } = formData;

        if (!currentPassword || !newPassword || !confirmPassword) {
            setStatus(prev => ({ ...prev, error: 'សូមបំពេញគ្រប់ប្រអប់ទាំងអស់។' }));
            return false;
        }

        if (newPassword !== confirmPassword) {
            setStatus(prev => ({ ...prev, error: 'ពាក្យសម្ងាត់ថ្មីមិនត្រូវគ្នា។' }));
            return false;
        }

        return true;
    };

    const handleChangePassword = async (e) => {
        e.preventDefault();

        if (!validateForm()) return;

        try {
            setStatus(prev => ({ ...prev, loading: true }));
            const token = localStorage.getItem('token');

            await axios.post(
                `${urlAPI}/user/change-password`,
                {
                    userId: state.user.id,
                    oldPassword: formData.currentPassword,
                    newPassword: formData.newPassword,
                    confirmPassword: formData.confirmPassword,
                },
                { headers: { Authorization: `Bearer ${token}` } }
            );

            setStatus({
                error: null,
                success: 'ពាក្យសម្ងាត់ត្រូវបានធ្វើបច្ចុប្បន្នភាពដោយជោគជ័យ។',
                loading: false
            });

            setTimeout(() => navigate('/user-management'), 1000);

        } catch (err) {
            setStatus({
                loading: false,
                error: err.response?.status === 400 
                    ? err.response.data.message 
                    : 'បណ្តាញមានបញ្ហា។ សូមព្យាយាមម្តងទៀតនៅពេលក្រោយ។',
                success: null
            });
        }
    };

    const { currentPassword, newPassword, confirmPassword } = formData;
    const { error, success, loading } = status;

    return (
        <div className="container">
            <h2>ប្តូរពាក្យសម្ងាត់សម្រាប់ {state.user.username}</h2>
            {error && (
                <div className="error-message-container">
                    <p className="error-message">{error}</p>
                </div>
            )}
            {success && <p className="success-message">{success}</p>}

            <form onSubmit={handleChangePassword} className="form-container">
                <table className="table-container">
                    <tbody>
                        <tr>
                            <td><label>ពាក្យសម្ងាត់បច្ចុប្បន្ន:</label></td>
                        </tr>
                        <tr>
                            <td>
                                <input
                                    type="password"
                                    value={currentPassword}
                                    onChange={(e) => handleInputChange('currentPassword', e.target.value)}
                                    required
                                    placeholder="បញ្ចូលពាក្យសម្ងាត់បច្ចុប្បន្ន"
                                    className="input-field"
                                />
                            </td>
                        </tr>

                        <tr>
                            <td><label>ពាក្យសម្ងាត់ថ្មី:</label></td>
                        </tr>
                        <tr>
                            <td>
                                <input
                                    type="password"
                                    value={newPassword}
                                    onChange={(e) => handleInputChange('newPassword', e.target.value)}
                                    required
                                    placeholder="បញ្ចូលពាក្យសម្ងាត់ថ្មី"
                                    className="input-field"
                                />
                            </td>
                        </tr>

                        <tr>
                            <td><label>បញ្ជាក់ពាក្យសម្ងាត់ថ្មី:</label></td>
                        </tr>
                        <tr>
                            <td>
                                <input
                                    type="password"
                                    value={confirmPassword}
                                    onChange={(e) => handleInputChange('confirmPassword', e.target.value)}
                                    required
                                    placeholder="បញ្ជាក់ពាក្យសម្ងាត់ថ្មី"
                                    className="input-field"
                                />
                            </td>
                        </tr>

                        <tr>
                            <td colSpan={2} style={{ textAlign: 'right' }}>
                                <Button
                                    onClick={() => navigate('/user-management')}
                                    variant="close-button"
                                    label="ចាកចេញ"
                                />
                                <Button
                                    type="submit"
                                    className="save-button"
                                    label={loading ? 'កំពុងប្តូរ...' : 'ប្តូរ'}
                                    disabled={loading}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </div>
    );
}

export default ChangePassword;
