import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './../../../Global-Form.css';
import Button from '../../../components/Button';
import Dropdown from '../../../components/Dropdown';

function NewSchedule() {
    const urlAPI = process.env.REACT_APP_API_URL;

    const [departmentId, setDepartment] = useState('');
    const [departmentIds, setDepartments] = useState([]);

    const [roomId, setRoom] = useState('');
    const [roomIds, setRooms] = useState([]);

    const [participant, setParticipant] = useState('');
    const [participants, setParticipants] = useState([]);

    const [isLeader, setIsLeader] = useState(0);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');

    const [letterNumber, setLetterNumber] = useState('');
    const [letterDate, setLetterDate] = useState('');
    const [place, setPlace] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [alert, setAlert] = useState('');
    const [other, setOther] = useState('');

    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null); // For success messages
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    // Fetch data from the API when component mounts
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const token = localStorage.getItem('token');
                const headers = { Authorization: `Bearer ${token}` };

                // Using Promise.all to fetch all data simultaneously
                const [deptResponse, roomResponse, participantResponse] =
                    await Promise.all([
                        axios.get(`${urlAPI}/department/gets`, { headers }),
                        axios.get(`${urlAPI}/room/gets`, { headers }),
                        axios.get(`${urlAPI}/participant/gets`, { headers }),
                    ]);

                setDepartments(deptResponse.data.data);
                setRooms(roomResponse.data.data);
                setParticipants(participantResponse.data.data)

                setError(null);
            } catch (err) {
                // Check if it's a 400 error and display the backend message
                if (err.response && err.response.status === 400) {
                    setError(err.response.data.message); // Show specific error message from the backend
                } else {
                    setError('បណ្តាញមានបញ្ហា។ សូមព្យាយាមម្តងទៀតនៅពេលក្រោយ។');
                }
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [urlAPI]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!departmentId || !roomId || !letterNumber || !letterDate || !place || !startDate || !endDate) {
            setError("តម្រូវអោយបំពេញគ្រប់ប្រអប់ទាំងអស់។");
            return;
        }

        try {
            const token = localStorage.getItem('token');
            // const userId = localStorage.getItem('userId');

            // Ensure `letterDate` is in the `yyyy-MM-dd` format
            const formattedDate = new Date(letterDate).toISOString().split('T')[0];
            const formattedStartDate = new Date(startDate).toISOString().split('T')[0];
            const formattedEndDate = new Date(endDate).toISOString().split('T')[0];
            setLoading(true); // Stop loading
            await axios.post(
                `${urlAPI}/schedule/create`,
                {
                    departmentId,
                    title,
                    description,
                    letterNumber,
                    letterDate: formattedDate,
                    place,
                    startDate: formattedStartDate,
                    endDate: formattedEndDate,
                    other,
                    alert,
                    roomId,
                    isLeader,
                    participantId: participant
                },
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );

            setSuccess('កាលវិភាគត្រូវបានបង្កើតដោយជោគជ័យ!');
            setError(null); // Clear any previous error messages
            setLoading(false); // Stop loading
            setTimeout(() => {
                navigate('/schedule');
            }, 1000);
        } catch (err) {
            setLoading(false); // Stop loading
            // Check if it's a 400 error and display the backend message
            if (err.response && err.response.status === 400) {
                setError(err.response.data.message); // Show specific error message from the backend
            } else {
                setError('បណ្តាញមានបញ្ហា។ សូមព្យាយាមម្តងទៀតនៅពេលក្រោយ។');
            }
        }
    };

    return (
        <div className="container">
            <h2>កាលវិភាគ</h2>
            {error && (
                <div className="error-message-container">
                    <p className="error-message">{error}</p>
                </div>
            )}
            {success && <p className="success-message">{success}</p>}

            {loading ? (
                <div className="loading-container">
                    <div className="spinner"></div>
                    <p className="loading-text">កំពុងផ្ទុក...</p>
                </div>
            ) : (
                <form onSubmit={handleSubmit} className="form-container">
                    <table className="table-container">
                        <tbody>
                            <tr>
                                <td><label>ប្រភពមកពី: </label></td>
                                <td><label>លេខលិខិត: </label></td>
                            </tr>
                            <tr>
                                <td>
                                    <Dropdown
                                        value={departmentId} // Ensure gender is handled properly
                                        onChange={(e) => setDepartment(e.target.value)} // Set department to the selected ID
                                        options={[
                                            { value: '', label: 'ជ្រើសរើសនាយកដ្ឋាន' },
                                            ...departmentIds.map((data) => ({
                                                value: data.id,  // Use ID as the value
                                                label: data.department_name, // Display department name
                                            }))
                                        ]}
                                        className="input-field-dropdown"
                                        required
                                    />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        value={letterNumber}
                                        onChange={(e) => setLetterNumber(e.target.value)}
                                        required
                                        placeholder="បញ្ចូលលេខលិខិត"
                                        className="input-field"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td><label>ថ្ងៃខែលិខិត:</label></td>
                                <td><label>ទីតាំង: </label></td>
                            </tr>
                            <tr>
                                <td>
                                    <input
                                        type="date"
                                        value={letterDate}
                                        onChange={(e) => setLetterDate(e.target.value)}
                                        required
                                        placeholder="បញ្ចូលថ្ងៃខែលិខិត"
                                        className="input-field"
                                    />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        value={place}
                                        onChange={(e) => setPlace(e.target.value)}
                                        required
                                        placeholder="បញ្ចូលទីតាំង"
                                        className="input-field"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td><label>ថ្ងៃខែចាប់ផ្តើម:</label></td>
                                <td><label>ថ្ងៃខែបញ្ចប់: </label></td>
                            </tr>
                            <tr>
                                <td>
                                    <input
                                        type="datetime-local"
                                        value={startDate}
                                        onChange={(e) => setStartDate(e.target.value)}
                                        required
                                        placeholder="បញ្ចូលថ្ងៃខែចាប់ផ្តើម"
                                        className="input-field"
                                    />
                                </td>
                                <td>
                                    <input
                                        type="datetime-local"
                                        value={endDate}
                                        onChange={(e) => setEndDate(e.target.value)}
                                        required
                                        placeholder="បញ្ចូលថ្ងៃខែបញ្ចប់"
                                        className="input-field"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td><label>អ្នកដឹកនាំប្រជុំ: </label></td>
                                <td><label>បន្ទប់ប្រជុំ: </label></td>
                            </tr>
                            <tr>
                                <td>
                                    <Dropdown
                                        value={participant}
                                        onChange={(e) => setParticipant(e.target.value)}
                                        options={[
                                            { value: '', label: 'ជ្រើសរើសអ្នកចូលរួម' },
                                            ...participants.map((data) =>
                                            ({
                                                value: data.id,
                                                label: data.participant_name,
                                            }))
                                        ]}
                                        className="input-field-dropdown"
                                        required
                                    />
                                </td>
                                <td>
                                    <Dropdown
                                        value={roomId}
                                        onChange={(e) => setRoom(e.target.value)}
                                        options={[
                                            { value: '', label: 'ជ្រើសរើសបន្ទប់' },
                                            ...roomIds.map((data) => ({
                                                value: data.id,
                                                label: data.name,
                                            }))
                                        ]}
                                        className="input-field-dropdown"
                                        required
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td><label>ការជូនដំណឹង (គិតជាម៉ង): </label></td>
                            </tr>
                            <tr>
                                <td>
                                    <input
                                        type="number"
                                        value={alert}
                                        onChange={(e) => setAlert(e.target.value)}
                                        required
                                        placeholder="បញ្ចូលការជូនដំណឹង"
                                        className="input-field"
                                    />
                                </td>
                                <td>
                                    <input
                                        type="checkbox"
                                        id="isLeader"
                                        checked={isLeader} // Boolean state to track checkbox state
                                        onChange={(e) => setIsLeader(e.target.checked)} // Updates the state based on checked/unchecked
                                        className="input-field-checkbox"
                                    />
                                    <label htmlFor="isLeader">សម្រាប់ថ្នាក់ដឹកនាំ</label>
                                </td>
                            </tr>
                            <tr>
                                <td><label>ផ្សេងៗ: </label></td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <input
                                        type="text"
                                        value={other}
                                        onChange={(e) => setOther(e.target.value)}
                                        placeholder="បញ្ចូលផ្សេងៗ"
                                        className="input-field"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td><label>ចំណងជើង:</label></td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <input
                                        type="text"
                                        value={title || ''}
                                        onChange={(e) => setTitle(e.target.value)}
                                        required
                                        placeholder="បញ្ចូលចំណងជើង"
                                        className="input-field"
                                    />
                                </td>
                            </tr>

                            <tr>
                                <td><label>កម្មវត្ថុ:</label></td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <textarea
                                        value={description || ''}
                                        onChange={(e) => setDescription(e.target.value)}
                                        required
                                        placeholder="បញ្ចូលកម្មវត្ថុ"
                                        className="input-field"
                                        rows={4}
                                    />
                                </td>
                            </tr>

                            <tr>
                                <td colSpan={2} style={{ textAlign: 'right' }}>
                                    <Button
                                        onClick={() => navigate('/schedule')}
                                        variant="close-button"
                                        label="ចាកចេញ"
                                    />
                                    <Button
                                        type="submit"
                                        className="save-button"
                                        label={loading ? 'កំពុងបង្កើត...' : 'បង្កើត'}
                                        disabled={loading} // Disable button while loading
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form>
            )}
        </div>
    );
}

export default NewSchedule;
