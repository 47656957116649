import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './../../Global.css';
import Button from '../../components/Button';

function Participant() {
  const urlAPI = process.env.REACT_APP_API_URL;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const navigate = useNavigate();

  const fetchParticipantDetails = useCallback(async (participant, token) => {
    try {
      const [positionResponse, departmentResponse, genderResponse] = await Promise.all([
        axios.get(`${urlAPI}/position/get/${participant.position_id}`, {
          headers: { Authorization: `Bearer ${token}` }
        }),
        axios.get(`${urlAPI}/department/get/${participant.department_id}`, {
          headers: { Authorization: `Bearer ${token}` }
        }),
        axios.get(`${urlAPI}/gender/get/${participant.gender_id}`, {
          headers: { Authorization: `Bearer ${token}` }
        })
      ]);

      return {
        ...participant,
        position_name: positionResponse?.data?.data[0]?.position_name || 'គ្មាន',
        department_name: departmentResponse?.data?.data?.department_name || 'គ្មាន',
        gender_name: genderResponse?.data?.data?.name || 'គ្មាន'
      };
    } catch (err) {
      console.error('Error fetching participant details:', err);
      return {
        ...participant,
        position_name: 'គ្មាន',
        department_name: 'គ្មាន',
        gender_name: 'គ្មាន'
      };
    }
  }, [urlAPI]);

  const handleError = (err) => {
    if (err.response?.status === 400) {
      setError(err.response.data.message);
    } else {
      setError('បណ្តាញមានបញ្ហា។ សូមព្យាយាមម្តងទៀតនៅពេលក្រោយ។');
    }
  };

  const fetchParticipant = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const permissionId = localStorage.getItem('permission-role');

      setUserRole(Number(permissionId));

      if (!token) {
        setError('Token not found. Please login again.');
        setLoading(false);
        return;
      }

      const response = await axios.get(`${urlAPI}/participant/gets`, {
        headers: { Authorization: `Bearer ${token}` }
      });

      const participants = response?.data?.data || [];
      const enrichedParticipants = await Promise.all(
        participants.map(participant => fetchParticipantDetails(participant, token))
      );

      setData(enrichedParticipants);
      setError('');
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  }, [urlAPI, fetchParticipantDetails]);

  const deleteParticipant = async (participantId) => {
    const confirmed = window.confirm('តើអ្នកប្រាកដថាចង់លុបអ្នកចូលរួមនេះមែនទេ?');
    if (!confirmed) return;

    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setError('Token not found. Please login again.');
        return;
      }

      await axios.delete(`${urlAPI}/participant/delete/${participantId}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      alert('អ្នកចូលរួមត្រូវបានលុបដោយជោគជ័យ។');
      fetchParticipant();
    } catch (err) {
      handleError(err);
    }
  };

  const openModal = (data, action) => {
    const route = action === 'edit' ? '/edit-participant' : '/new-participant';
    const state = action === 'edit' ? { state: { data } } : undefined;
    navigate(route, state);
  };

  useEffect(() => {
    fetchParticipant();
  }, [fetchParticipant]);

  const renderLoadingSpinner = () => (
    <div className="loading-container">
      <div className="spinner"></div>
      <p className="loading-text">កំពុងផ្ទុក...</p>
    </div>
  );

  const renderError = () => (
    <div className="error-message-container">
      <p className="error-message">{error}</p>
    </div>
  );

  const renderParticipantTable = () => (
    <table className="data-table">
      <thead>
        <tr>
          <th>លេខ.</th>
          <th>ឈ្មោះពេញ</th>
          <th>ភេទ</th>
          <th>ឈ្មោះកាត់</th>
          <th>ឋានះ ឫតួនាទី</th>
          <th>នៅអង្គភាព</th>
          <th colSpan={userRole === 3 ? 1 : 2}>សកម្មភាព</th>
        </tr>
      </thead>
      <tbody>
        {data.map((participant, index) => (
          <tr key={participant.id}>
            <td>{index + 1}</td>
            <td>{participant.participant_name || 'គ្មាន'}</td>
            <td>{participant.gender_name || 'គ្មាន'}</td>
            <td>{participant.short_name || 'គ្មាន'}</td>
            <td>{participant.position_name || 'គ្មាន'}</td>
            <td>{participant.department_name || 'គ្មាន'}</td>
            {userRole === 3 ? (
              <td>
                <Button
                  onClick={() => navigate('/view-participant', { state: { data: participant } })}
                  className="view-button"
                  label="មើល"
                />
              </td>
            ) : (
              <>
                <td>
                  <Button
                    onClick={() => openModal(participant, 'edit')}
                    className="update-button"
                    label="កែប្រែ"
                  />
                </td>
                <td>
                  <Button
                    onClick={() => deleteParticipant(participant.id)}
                    className="delete-button"
                    label="លុប"
                  />
                </td>
              </>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );

  return (
    <div className="home-container">
      <h1>តារាងអ្នកចូលរួម</h1>

      {userRole !== 3 && (
        <Button
          onClick={() => openModal(null, 'new')}
          className="update-button"
          label="បង្កើតថ្មី"
        />
      )}

      {loading && renderLoadingSpinner()}
      {error && renderError()}
      {!loading && data.length > 0 && renderParticipantTable()}
      {!loading && data.length === 0 && <p>រកមិនឃើញអ្នកចូលរួម។</p>}
    </div>
  );
}

export default Participant;
