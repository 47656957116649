import { jwtDecode } from 'jwt-decode';

const LOCAL_STORAGE_KEYS = {
  TOKEN: 'token',
  ROLE: 'permission-role',
  NAME: 'name',
  PARTICIPANT: 'data-owner-participant',
  USER_ID: 'user-id'
};

export const isAuthenticated = () => {
  const token = localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN);
  if (!token) return false;
  
  try {
    const decodedToken = jwtDecode(token);
    const { role, name, participant, exp, user_id } = decodedToken;

    localStorage.setItem(LOCAL_STORAGE_KEYS.ROLE, role);
    localStorage.setItem(LOCAL_STORAGE_KEYS.NAME, name);
    localStorage.setItem(LOCAL_STORAGE_KEYS.PARTICIPANT, participant);
    localStorage.setItem(LOCAL_STORAGE_KEYS.USER_ID, user_id);

    const currentTimeInSeconds = Date.now() / 1000;
    return exp > currentTimeInSeconds;
    
  } catch (error) {
    console.error('Invalid token format:', error);
    return false;
  }
};

export const handleLogout = () => {
  Object.values(LOCAL_STORAGE_KEYS).forEach(key => {
    localStorage.removeItem(key);
  });
  window.location.href = '/';
};
