import React from 'react';
import PropTypes from 'prop-types';
import './Dropdown.css';

const DROPDOWN_DEFAULTS = {
    className: '',
    required: false
};

const Dropdown = ({
    value,
    onChange,
    options,
    className = DROPDOWN_DEFAULTS.className,
    required = DROPDOWN_DEFAULTS.required
}) => {
    const dropdownClasses = [className].filter(Boolean).join(' ');

    const renderOption = (option) => {
        const key = option.value || option.label;
        return (
            <option key={key} value={option.value}>
                {option.label}
            </option>
        );
    };

    return (
        <select
            value={value}
            onChange={onChange}
            required={required}
            className={dropdownClasses}
        >
            {options.map(renderOption)}
        </select>
    );
};

const valueType = PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
]);

Dropdown.propTypes = {
    value: valueType,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: valueType.isRequired,
            label: PropTypes.string.isRequired,
        })
    ).isRequired,
    className: PropTypes.string,
    required: PropTypes.bool
};

export default Dropdown;
